<template>
  <v-container>
    <div>
      <div class="d-flex align-center">
        <span class="mr-4 my-4 text-h6 font-weight-black">CRITERIA</span>
        <v-divider></v-divider>
      </div>
      <div class="ml-4 mb-4 d-flex">
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text" small :disabled="isModifying" @click="handleCriteriaModify">
          modify
          <v-icon size="20" class="ml-1" color="white">mdi-rename-outline</v-icon>
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small>
          <v-icon size="20" class="mr-1" color="white">mdi-robot-outline</v-icon>
          Generated by AI
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleAddCriteria">
          <v-icon size="20" class="mr-1" color="white">mdi-plus</v-icon>
          New Criteration
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleSaveModify" :loading="modifyLoading">
          save
          <v-icon size="20" class="ml-1" color="white">mdi-content-save-edit-outline</v-icon>
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleCancelModify">
          cancel
          <v-icon size="20" class="ml-1" color="white">mdi-cancel</v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
    </div>

    <div class="mt-4">
      <v-card elevation="3">
        <v-card-title>
          Criteria List
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="criteriaDataTable"
          item-key="Criteria"
          class="elevation-1"
          fixed-header
        >
          <template v-slot:[`item.Criteria`]="{ item }">
            <span class="ml-4 font-weight-bold">{{ item.Criteria }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
              small
              class="mr-2"
              @click="handleEditCriteria(index)"
              :disabled="!isModifying"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="handleDeleteCriteria(index, item.Criteria)"
              :disabled="!isModifying"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleAddCriteria">
              <v-icon size="20" class="mr-1" color="white">mdi-plus</v-icon>
              New Criteration
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-dialog
      v-model="criteriaDialog"
      persistent
      max-width="666"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-textarea
            v-model="editName"
            class="mt-4"
            rows="1"
            dense
            solo
            label="Criteria Name"
          ></v-textarea>
        </v-card-title>
        <div class="ml-4 mt-n2">
          <span class="text-body-2 grey--text ml-2">Highest ranked</span>
          <draggable v-model="editLevelItems" >
            <div v-for="(item, index) in editLevelItems" :key="index">
              <v-divider v-if="index !== 0"></v-divider>
              <v-list dense>
                <v-list-item dense>
                  <v-icon size="20" class="ml-1 drag-handle">mdi-cursor-move</v-icon>
                  <v-list-item-content class="ml-3">
                    <v-text-field
                      v-if="item.isRewrite"
                      dense
                      width="200"
                      v-model="item.content"
                      class="mr-4 mb-n8 mt-n2"
                      append-icon="mdi-arrow-right-bold-box"
                      @click:append="item.isRewrite = false"
                      solo
                    ></v-text-field>
                    <v-list-item-title v-else>{{ item.content }}</v-list-item-title>
                    
                  </v-list-item-content>
                  
                  <v-btn class="mr-4" icon color="dark-grey" x-small @click="item.isRewrite = true">
                    <v-icon>mdi-rename-outline</v-icon>
                  </v-btn>
                  <v-btn class="mr-4" icon color="dark-grey" x-small @click="handleDeleteCriteriaLevel(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list>
            </div>
          </draggable>
          <span class="text-body-2 grey--text ml-2">Lowest ranked</span>
          <br>
          <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-2 mt-2" small @click="handleAddCriteriaLevel">
            <v-icon size="20" class="mr-1" color="white">mdi-plus</v-icon>
            New Level
          </v-btn>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="criteriaDialog = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="handleConfirmModify"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Are you sure to delete {{ criteriaDeleteName }} ?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="deleteDialog = false"
            class='text-capitalize font-weight-bold'
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureDelete"
            :loading="sureDeleteloading"
            class='text-capitalize white--text font-weight-bold'
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import draggable from 'vuedraggable';
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'ProjectCriteria',

  components: {
    draggable,
  },

  data() {
    return {
      isLogin: false,
      userID: '',
      token: '',

      criteriaDialog: false,
      editMode: undefined,
      editName: '',
      editLevelItems: [],

      deleteDialog: false,
      criteriaDeleteIndex: undefined,
      criteriaDeleteName: '',
      sureDeleteloading: false,

      selected: [],
      headers: [
        {
          text: 'Criteria',
          align: 'start',
          sortable: false,
          value: 'Criteria',
          class: 'font-weight-bold text-body-1'
        },
        { 
          text: 'Levels',
          value: 'Levels', 
          align: 'center', 
          sortable: false, 
          class: 'font-weight-bold text-body-1'
        },
        { 
          text: 'Actions', 
          align: 'center',
          value: 'actions', 
          sortable: false, 
          width: 88,
          class: 'font-weight-bold text-body-1'
        },

      ],

      criteriaItems: [],

      isModifying: false,
      modifyLoading: false,
      decisionStatus: {},
    }
  },

  computed: {
    criteriaDataTable() {
      let returnTags = []
      let midItem = {}
      this.criteriaItems.forEach((criteriaItem, index) => {
        midItem.Criteria = criteriaItem.criteriaTitle;
        midItem.Levels = criteriaItem.criteriaLevelItems.map(obj => obj.content).join(', ')
        returnTags.push(midItem);
        midItem = {}
      })
      return returnTags
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.criteriaItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
        this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.criteriaItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
    this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});
  },

  mounted() {

  },
  
  methods: {
    handleAddCriteria() {
      this.editName = '';
      this.editLevelItems = [];
      this.editMode = 'create';
      this.criteriaDialog = true;
    },

    handleEditCriteria(index) {
      this.editName = this.criteriaItems[index].criteriaTitle;
      this.editLevelItems = [ ...this.criteriaItems[index].criteriaLevelItems ];
      this.editMode = index;
      this.criteriaDialog = true;
    },
    
    handleAddCriteriaLevel() {
      let addCriteriaLevel = {
        content: '',
        isRewrite: true,
      }
      this.editLevelItems.push(addCriteriaLevel);
    },

    handleDeleteCriteriaLevel(index) {
      this.editLevelItems.splice(index, 1);
    },
    
    handleConfirmModify() {
      let criteriaItem = {}
      if (this.editName == '') {
        this.$store.commit('HandleADecisionSnackbar', 'Please enter criteria title');
      } else {
        if (this.editLevelItems.length < 2) {
          this.$store.commit('HandleADecisionSnackbar', 'Please enter at least 2 levels');
        } else {
          if (this.editMode == 'create') {
            criteriaItem = {
              criteriaTitle: this.editName,
              criteriaLevelItems: this.editLevelItems,
            }
            this.criteriaItems.push(criteriaItem)
          } else {
            criteriaItem = {
              criteriaTitle: this.editName,
              criteriaLevelItems: this.editLevelItems,
            }
            this.$set(this.criteriaItems, this.editMode, criteriaItem);
          }
          this.criteriaDialog = false;
        }
      }
      
    },

    handleCriteriaModify() {
      if (this.isLogin) {
        if (this.projectItemMain.projcetID == '') {
          this.$store.commit('HandleADecisionSnackbar', 'Please Wait')
        } else {
          this.isModifying = true;
        }
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Please Login')
      }
    },

    async handleSaveModify() {
      this.$store.commit('HandleloadingMsg', "criteria modified loading");
      this.modifyLoading = true;

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': this.$store.getters.getprojectItemMain.projectID,
        'decisionCriteria': JSON.stringify(this.criteriaItems),
        'decisionStatus': JSON.stringify(this.handleMakeDecisionStatus()),
        'decisionLastChangeTime': Date.now(),
      };
      
      let request_url = '/requestModifyCriteria';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.modifyLoading = false;
        this.$store.commit('HandleloadingMsg', "");
        this.$store.commit('HandleADecisionSnackbar', 'Success to modify');
        this.isModifying = false;

        this.$store.commit('HandlerefreshSingleProject', true);
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to create project:' + res.statusInfo);
      }
    },

    handleCancelModify() {
      this.isModifying = false;
      this.$store.commit('HandlerefreshSingleProject', true);
    },

    handleDeleteCriteria(index, Name) {
      this.criteriaDeleteIndex = index;
      this.criteriaDeleteName = Name;
      this.deleteDialog = true;
    },

    handleSureDelete() {
      this.sureDeleteloading = true;
      this.criteriaItems.splice(this.criteriaDeleteIndex, 1);
      this.deleteDialog = false;
      this.sureDeleteloading = false;
    },

    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },

    handleMakeDecisionStatus() {
      let status = this.decisionStatus
      if (this.criteriaItems.length > 0) {
        status.criteria = true;
        status.tradeOff = false;
        status.analyse = false;
      } else {
        status.criteria = false;
        status.tradeOff = false;
        status.analyse = false;
      }
      return status
    }
  }
};
</script>

<style scoped>
.drag-handle {
  cursor: move;
}
</style>