<template>
    <div>
      CriteriaView
    </div>
  </template>
  
  <script>``
  
  export default {
    name: 'CriteriaView',
  
    components: {
    },
  
    data() {
      return {
      }
    },
  
    methods: {
    }
  };
  </script>
  