<template>
  <div class="d-flex">
    <YA_ScrollComponent ref="ScrollComRef" :scrollHeight="40">
      <v-container>
        <div>
          <div class="d-flex align-center">
            <span class="mr-4 my-4 text-h6 font-weight-black">RESULTS</span>
            <v-divider></v-divider>
          </div>
        </div>

        <div class="mt-4">
          <v-card outlined>
            <span class="text-body-1 float-start mt-n3 ml-2 px-2" style="background-color: white; color: #CC3333;">Preference Analysis</span>
            <v-container>
              <p class="text-body-2 mt-2 px-2">These numbers, determined by your trade-offs, represent the relative importance of your criteria.</p>
              <div class="pa-4">
                <canvas ref="perferanceBarChart"></canvas>
              </div>
            </v-container>
            <div class="d-flex justify-center">
              <v-btn color="#439798" class="text-capitalize text-body-1 my-4" outlined @click="handlePreferenceAnalysis">
                Preference Analysis
              </v-btn>
            </div>
          </v-card>
          <v-card outlined class="mt-8 mb-4">
            <span class="text-body-1 float-start mt-n3 ml-2 px-2" style="background-color: white; color: #CC3333;">Alternatives Analysis</span>
            <v-container>
              <p class="text-body-2 mt-2 px-2">Your alternatives ranked by their total score, based on your preference values.</p>
              <div class="pa-4">
                <v-list>
                  <template v-for="(item, index) in alternativesRateItems">
                    <v-divider
                      v-if="index !== 0"
                      :key="index"
                      inset
                    ></v-divider>

                    <v-list-item
                      :key="item.name"
                    >
                      <v-row>
                        <v-col cols="3" class="d-flex align-center">
                          <span  class="font-weight-black">{{ item.name }}</span>
                        </v-col>
                        <v-col cols="9">
                          <YA_AlternativesProgress :data="item.rate" />
                        </v-col>
                      </v-row>

                    </v-list-item>
                  </template>
                </v-list>
              </div>
            </v-container>
            
            <div class="d-flex justify-center">
              <v-btn color="#439798" class="text-capitalize text-body-1 my-4" outlined @click="handleAlternativesAnalysis">
                Alternatives Analysis
              </v-btn>
            </div>
          </v-card>
        </div>
      </v-container>
    </YA_ScrollComponent>
    <v-divider vertical></v-divider>

  </div>
</template>
  
<script>
import Chart from 'chart.js/auto';
import YA_ScrollComponent from '@/components/SocialComponents/YA_ScrollComponent.vue';
import YA_AlternativesProgress from '@/components/ProjectComponents/AlternativesComponents/YA_AlternativesProgress.vue';

export default {
  name: 'AnalyseSummaryResult',

  components: {
    YA_ScrollComponent,
    YA_AlternativesProgress,
  },

  data() {
    return {
      decisionAnalyse: {},
      projectItemMain: {},
      decisionAlternatives: [],

      isLogin: false,
      userID: '',
      token: '',

      alternativesRateItems: [],

      chartInstance: null,
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.decisionAnalyse = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAnalyse || {});
        this.decisionAlternatives = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAlternatives || []);
        if (Object.keys(this.decisionAnalyse).length !== 0) {
          this.handleInitPreferenceChart();
          this.handleGetAlternativeRecord();
        }
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.decisionAnalyse = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAnalyse || {});
    this.decisionAlternatives = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAlternatives || []);
    if (Object.keys(this.decisionAnalyse).length !== 0) {
      this.handleInitPreferenceChart();
      this.handleGetAlternativeRecord();
    }
  },

  mounted() {
  },
  
  methods: {
    handleInitPreferenceChart() {
      let keys = this.decisionAnalyse.criteriaRate.map(item => item.key);
      let criteriaRates = this.decisionAnalyse.criteriaRate.map(item => item.criteriaRate);
      this.$nextTick(()=> {
        this.generatePreferenceChart(keys, criteriaRates)
      })
    },

    generatePreferenceChart(tags, data) {
      const ctx = this.$refs.perferanceBarChart.getContext('2d');
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
      this.chartInstance = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: tags,
          datasets: [{
            data: data,
            backgroundColor: '#67c9cb',
            borderWidth: 1
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false 
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  var label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += (context.parsed.y * 100).toFixed(2) + '%';
                  }
                  return label;
                }
              }
            }
          },
          scales: {
            y: {
              grid: {
                display: true ,
              },
              ticks: {
                callback: function(value) {
                  return (value * 100) + '%';
                }
              },
              border: {
                display: false 
              },
            },
            x: {
              grid: {
                display: false 
              }
            }
          }
        }
      });
    },

    handleGetAlternativeRecord() {
      let colors = [ 'red', 'blue', 'green', 'yellow', 'orange', 'purple', 'cyan', 'magenta', 'lime', 'brown' ];
      
      this.alternativesRateItems = [];
      this.decisionAlternatives.forEach((alternativeItem, alternativeIndex)=> {
        let alternativeRateItem = {}
        alternativeRateItem['name'] = alternativeItem.alternativeName

        let rateItems = [];
        let countNum = 0;
        Object.keys(alternativeItem.alternativeCriteriaChoice).forEach(key => {
          let rateItem = {}
          rateItem['name'] = key
          rateItem['content'] = alternativeItem.alternativeCriteriaChoice[key]
          rateItem['color'] = colors[(countNum % 10)]
          countNum += 1;
          this.decisionAnalyse.alternativeRate.forEach((inItem, inIndex)=> {
            if ((inItem.key == key) && (inItem.value == alternativeItem.alternativeCriteriaChoice[key])) {
              rateItem['value'] = inItem.realChoiceRate
            }
          })
          rateItems.push(rateItem)

        });

        alternativeRateItem['rate'] = rateItems

        this.alternativesRateItems.push(alternativeRateItem)
      })
    },

    handlePreferenceAnalysis() {
      let routerName = 'Project/ProjectAnalysePreference'
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },
    handleAlternativesAnalysis() {
      let routerName = 'Project/ProjectAnalyseAlternatives'
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },

    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },
  }
};
</script>

<style scoped>

</style>

