import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

  const state= {
    userMsg: {
      isLogin: false,
      userID: '',
      userName: '',
      token: '',
      isProUser: true,
    },
    systemMsg: {
      leftBarTag: true,
      routerPath: "",
      ADecisionSnackbar: '',
      loadingMsg: "",
    },
    projectMsg: {
      projectItems: [],
      projectItemMain: {},
      refreshSingleProject: false,
    }
  }

  const getters= {
    // userMsg
    getisLogin() {
      return state.userMsg.isLogin
    },
    getuserID() {
      return state.userMsg.userID
    },
    getuserName() {
      return state.userMsg.userName
    },
    gettoken() {
      return state.userMsg.token
    },
    getisProUser() {
      return state.userMsg.isProUser
    },

    // systemMsg
    getleftBarTag() {
      return state.systemMsg.leftBarTag
    },
    getrouterPath() {
      return state.systemMsg.routerPath
    }, 
    getADecisionSnackbar() {
      return state.systemMsg.ADecisionSnackbar
    },
    getloadingMsg() {
      return state.systemMsg.loadingMsg
    },

    // projectMsg
    getprojectItems() {
      return state.projectMsg.projectItems
    },
    getprojectItemMain() {
      return state.projectMsg.projectItemMain
    },
    getrefreshSingleProject() {
      return state.projectMsg.refreshSingleProject
    },
  }

  const mutations= {
    // userMsg
    HandleisLogin(state, data) {
      state.userMsg.isLogin = data; 
    },
    HandleuserID(state, data) {
      state.userMsg.userID = data; 
    },
    HandleuserName(state, data) {
      state.userMsg.userName = data; 
    },
    Handletoken(state, data) {
      state.userMsg.token = data; 
    },
    HandleisProUser(state, data) {
      state.userMsg.isProUser = data; 
    },

    // systemMsg
    HandleleftBarTag(state, data) {
      state.systemMsg.leftBarTag = data; 
    },
    HandlerouterPath(state, data) {
      state.systemMsg.routerPath = data; 
    }, 
    HandleADecisionSnackbar(state, data) {
      state.systemMsg.ADecisionSnackbar = data; 
    },
    HandleloadingMsg(state, data) {
      state.systemMsg.loadingMsg = data; 
    },

    // projectMsg
    HandleprojectItems(state, data) {
      state.projectMsg.projectItems = data; 
    },
    HandleprojectItemMain(state, data) {
      state.projectMsg.projectItemMain = data; 
    },
    HandlerefreshSingleProject(state, data) {
      state.projectMsg.refreshSingleProject = data; 
    },
  }

export default new Vuex.Store({
    mutations:mutations,
    state:state,
    getters:getters,
})