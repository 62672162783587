<template>
  <div class="py-8">
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <span class="text-body-1 float-start mt-n3 ml-2 px-2" style="background-color: white; color: #CC3333;">Criterion value functions</span>
          <v-container>
            <p class="text-body-2 mt-2 px-2">Each criterion's value function shows the 'shape' of the preference values for the levels on the criterion.</p>
            <div class="pa-16">
              <canvas ref="preferanceEffectsBarChart"></canvas>
            </div>
          </v-container>
        </v-card>
      </v-col>

      <!-- <v-col cols="12">
        <v-card outlined>
          <span class="text-body-1 float-start mt-n3 ml-2 px-2" style="background-color: white; color: #CC3333;">Differences between the preference values of adjacent levels</span>
          <v-container>
            <p class="text-body-2 mt-2 px-2">Each preference value difference is the marginal effect of moving from the previous level on the criterion.</p>
          </v-container>

          <v-container>
            <div class="my-8 mx-8" v-for="(item, index) in PreferenceEffectsItems" :key="index">
              <v-row>
                <v-col cols="6">
                  <span class="text-body-1 font-weight-bold">{{ item.title }}</span>
                </v-col>
                <v-col cols="6" class="d-flex justify-center">
                  <v-row v-if="index == 0">
                    <v-col cols="6">
                      <span class="text-body-1 font-weight-bold float-right">Preference value</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="text-body-1 font-weight-bold float-left">Difference</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="mt-1"></v-divider>
              <v-row v-for="(levelItem, levelIndex) in item.levelItems" :key="levelIndex" class="mt-1">
                <v-col cols="6">
                  <span class="text-body-1 font-weight-bold ml-4">{{ levelItem.name }}</span>
                </v-col>
                <v-col cols="6" class="d-flex justify-center">
                  <v-row>
                    <v-col cols="6">
                      <span class="text-body-1 grey--text font-weight-bold float-right">{{ levelItem.preferValue.toFixed(1) }} %</span>
                    </v-col>
                    <v-col cols="6">
                      <span class="text-body-1 font-weight-bold">{{ levelItem.preferValue.toFixed(1) }} %</span>

                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template>
  
<script>
import Chart from 'chart.js/auto';

export default {
  name: 'AnalyseAlternativesCompare',

  components: {
  },

  props: {
    data: Array,
  },

  data() {
    return {
      preferenceEffectsTags: ["汤臣一品", "海淀"],
      preferenceEffectsLabels: ['气候', '污染', '靠近幼儿园'],
      preferenceEffectsData: [
        [0.23, 0.32, 0.12],
        [0.25, 0.12, 0.34],
      ],

      chartInstance: null,

      PreferenceEffectsItems: [
        {
          title: 'sasasa', 
          levelItems: [
            { name: 'sdfjaksdf', preferValue: 23 },
            { name: 'dsf', preferValue: 21 },
            { name: 'sdfjakr33fsdf', preferValue: 13 },
          ],
        },
        {
          title: 'sasasa', 
          levelItems: [
            { name: 'sdfjaksdf', preferValue: 23 },
            { name: 'dsf', preferValue: 21 },
            { name: 'sdfjakr33fsdf', preferValue: 13 },
          ],
        },
        {
          title: 'sasasa', 
          levelItems: [
            { name: 'sdfjaksdf', preferValue: 23 },
            { name: 'dsf', preferValue: 21 },
            { name: 'sdfjakr33fsdf', preferValue: 13 },
          ],
        },
        {
          title: 'sasasa', 
          levelItems: [
            { name: 'sdfjaksdf', preferValue: 23 },
            { name: 'dsf', preferValue: 21 },
            { name: 'sdfjakr33fsdf', preferValue: 13 },
          ],
        },
      ]
    }
  },

  created() {
    this.handleInitTags()
  },

  mounted() {
    this.handleMakeChart();
  },
  
  methods: {
    handleInitTags() {
      this.preferenceEffectsTags = [];
      this.preferenceEffectsLabels = [];
      this.preferenceEffectsData = [];
      let inTag = []
      this.data.forEach((dataItem, dataIndex)=> {
        this.preferenceEffectsTags.push(dataItem.alternativeName)
        inTag = []
        dataItem.contribution.forEach((item, index)=> {
          inTag.push(item.value)
        })
        this.preferenceEffectsData.push(inTag)
      })
      this.data[0].contribution.forEach((conItem, conIndex)=> {
        this.preferenceEffectsLabels.push(conItem.name)
      })
    },

    handleMakeChart() {
      let colors = [ 'rgba(255, 160, 180, 1)', 'rgba(255, 200, 144, 1)', 'rgba(255, 225, 154, 1)', 'rgba(165, 223, 223, 1)', 'rgba(154, 208, 245, 1)' ];
      let shape = [ 'rectRot', 'triangle', 'circle', 'rect', 'rectRounded' ];

      let datasets = [];
      let dataset = {};
      this.preferenceEffectsTags.forEach((item, index)=> {
        dataset = {};
        dataset['label'] = item;
        dataset['data'] = this.preferenceEffectsData[index];
        dataset['borderColor'] = colors[index % 10];
        dataset['tension'] = 0.2;
        dataset['pointStyle'] = shape[index % 5];
        dataset['pointRadius'] = 10;
        dataset['pointBorderColor'] = 'rgba(0, 0, 0, 0)';
        dataset['backgroundColor'] = colors[index % 10];
        dataset['borderWidth'] = 4;
        dataset['hoverBorderColor'] = 'rgba(255, 200, 144, 1)';
        datasets.push(dataset)
      })
      this.generatePreferenceEffectsChart(this.preferenceEffectsLabels, datasets);

    },

    generatePreferenceEffectsChart(labels, datasets) {
      const ctx = this.$refs.preferanceEffectsBarChart.getContext('2d');
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }
      this.chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        plugins: {
          tooltip: {
            // 启用提示工具
            enabled: true,
            // 自定义提示工具外观和行为
            mode: 'index',
            intersect: false,
            position: 'nearest',
          }
        },
        onHover: (event, chartElement, chart) => {
          if (chartElement.length > 0) {
            const hoveredIndex = chartElement[0].index;
            chart.data.datasets.forEach((dataset, index) => {
              if (index === chartElement[0].datasetIndex) {
                // 突出显示当前折线
                dataset.borderWidth = 4; // 增加边框宽度
                dataset.borderColor = 'rgba(255, 99, 132, 1)'; // 改变边框颜色
              } else {
                // 恢复其他折线的样式
                dataset.borderWidth = 2; // 默认边框宽度
                dataset.borderColor = 'rgba(0, 0, 0, 0.1)'; // 默认边框颜色
              }
            });
            chart.update();
          } else {
            // 鼠标不覆盖在任何数据点上时，恢复所有折线的默认样式
            chart.data.datasets.forEach((dataset) => {
              dataset.borderWidth = 2; // 默认边框宽度
              dataset.borderColor = dataset.backgroundColor; // 默认边框颜色
            });
            chart.update();
          }
        },
        scales: {
          // 配置坐标轴
        }
      }
    });
    },
  }
};
</script>

<style scoped>
</style>