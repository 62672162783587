<template>
  <v-container>
    <div>
      <div class="d-flex align-center">
        <span class="mr-4 my-4 text-h6 font-weight-black">PROJECT FILES</span>
        <v-divider></v-divider>
      </div>
      <div class="ml-4 mb-4 d-flex">
        <input type="file" accept=".pdf" ref="fileInput" @change="handleFileChange" style="display: none" />

        <v-btn color="#439798" class="text-capitalize text-body-1 white--text" :loading="ButtonLinksLoading" small @click="handleAddDoc">
          upload
          <v-icon size="20" class="ml-1" color="white">mdi-file-upload-outline</v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
    </div>
    <div class="d-flex justify-center" v-if="!isLogin">
        <span class="text-body-1 font-weight-bold mt-4">Please Login</span>
    </div>
    <div v-else>
      <div class="d-flex justify-center mt-6" v-if="getProjectFilesLoading">
        <v-progress-circular
          color="#296162"
          indeterminate
          size="48"
        ></v-progress-circular>
      </div>
      <div class="ml-4 mt-4" v-else>
        <div v-if="projectFileItems.length > 0" class="d-flex flex-wrap">
          <v-card
            v-for="(item, index) in projectFileItemsCom" 
            :key="index"
            class="d-flex flex-wrap mr-10 mb-12"
            flat
            max-width="140"
            max-height="240"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                class="d-flex justify-center align-center mr-4"
                min-width="140"
                min-height="160"
                outlined
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
              >
                <v-container class="d-flex justify-center flex-wrap align-center">
                  <v-icon
                    :color="item.color"
                    size="66"
                    class="mt-6"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <div class="align-self-center">
                    <v-btn :class="{ 'show-btns': !hover }" icon>
                      <v-icon :class="{ 'show-btns': !hover }">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                    <v-btn :class="{ 'show-btns': !hover }" icon @click="handleDeleteDoc(item.docID, item.docName)">
                      <v-icon :class="{ 'show-btns': !hover }">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </v-container>
              </v-card>
            </v-hover>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span class="mt-1 text-body-1" v-on="on">
                  <!-- <v-progress-circular
                    :size="20"
                    :color="item.color"
                    indeterminate
                    v-if="item.isUploading"
                  ></v-progress-circular> -->
                  {{ item.newName }}
                </span>
              </template>
              <span>{{ item.docName }}</span>
            </v-tooltip>
            <span class="text-body-2 grey--text">{{ item.createTime }}</span>

          </v-card>
        </div>

        <div v-else class="d-flex justify-center">
          <v-btn color="#439798" text class="text-capitalize text-body-1 font-weight-bold" @click="handleAddDoc">
            Create New Project Now
          </v-btn>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="deleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Are you sure to delete {{ docDeleteName }} ?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="deleteDialog = false"
            class='text-capitalize font-weight-bold'
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureDelete"
            :loading="sureDeleteloading"
            class='text-capitalize white--text font-weight-bold'
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { fileLoadText } from '@/utils/PowerFileUtils/LoadFileText.js';
import { getCookie, setCookie, removeCookie } from '@/utils/SystemUtils/cookies.js'
import { requestPost } from '@/utils/SystemUtils/request.js';
import { getBaseURL } from '@/utils/SystemUtils/url.js'
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'


export default {
  name: 'ProjectFile',

  components: {
  },

  data() {
    return {
      isLogin: false,
      userID: '',
      token: '',

      getProjectFilesLoading: false,

      deleteDialog: false,
      docDeleteID: '',
      docDeleteName: '',
      sureDeleteloading: false,

      DocLinksLoading: false,
      ButtonLinksLoading: false,

      fileMax: 100 * 1024 * 1024,

      projectFileItems: [],

      projectItemMain: {},
    }
  },

  computed: {
    projectFileItemsCom() {
      return this.projectFileItems.map(target => {
        if (target.docName.length > 30) {
          return {
            ...target,
            newName: target.docName.slice(0, 30) + '...'
          };
        } else {
          return {
            ...target,
            newName: target.docName
          };
        }
      });
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
  },

  mounted() {
    this.handleGetAllDoc();
  },

  activated() {
    this.handleGetAllDoc();
  },
  
  methods: {
    handleAddDoc() {
      if (this.isLogin) {
        if (this.projectItemMain.projcetID == '') {
          this.$store.commit('HandleADecisionSnackbar', 'Please Wait')
        } else {
          this.$refs.fileInput.click();
        }
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Please Login')
      }
    },

    async handleGetAllDoc() {
      this.getProjectFilesLoading = true;

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': getLocalStorage('ADecisionProjectID'),
      };

      let request_url = '/requestGetAllDoc';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        res.data.forEach(item => {
          item.createTime = this.formatDate(parseInt(item.createTime, 10))
          item.icon = "mdi-file-document-outline";
          item.color = "red";
        });
        this.projectFileItems = res.data;
        this.getProjectFilesLoading = false;
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to retrieve DocLinks: ' + res.statusInfo)
      }
    },

    async handleFileChange(event) {
      this.ButtonLinksLoading = true;
      const fileInput = event.target;
      if (fileInput.files.length > 0) {
        if (fileInput.files[0].size < this.fileMax) {
          const fileContent = await fileLoadText(fileInput.files[0]);
          if (fileContent.code == 200) {
            let docUrlRes = await this.handleFileUpload(fileInput.files[0]);

            let request_header = {
              email: this.userID,
              token: this.token
            };
            let request_json = {
              'did': getCookie('did'),
              'special': {
                'referer':document.referrer||'no-referer',
                'path':location.href
              },
              
              'docName': fileInput.files[0].name,
              'docUrl': docUrlRes,
              'docContentArray': fileContent.content.docContentArray,
              'docPageNumberArray': fileContent.content.docPageNumberArray,
              'projectID': this.$store.getters.getprojectItemMain.projectID,
            };
            let request_url = '/requestUploadFileContent';
            const res = await requestPost(request_header, request_json, request_url);

            if (res.statusCode == 1) {
              this.ButtonLinksLoading = false;
              this.$refs.fileInput.value = '';

              this.handleGetAllDoc();

              this.$store.commit('HandleADecisionSnackbar', 'Success to add the document')
            } else {
              this.$store.commit('HandleADecisionSnackbar', 'Failed to add the document: ' + res.statusInfo)
              this.ButtonLinksLoading = false;
              this.$refs.fileInput.value = '';
            }
          } else {
            this.$store.commit('HandleADecisionSnackbar', fileContent.msg)
            this.ButtonLinksLoading = false;
            this.$refs.fileInput.value = '';
          }
        } else {
          this.$store.commit('HandleADecisionSnackbar', 'The file is too large, please choose a file smaller than 100MB.')
          this.selectedFile = null;
        }
      } else {
        this.ButtonLinksLoading = false;
        this.$refs.fileInput.value = '';
      }
    },

    async handleFileUpload(fileBody) {
      const formData = new FormData();

      formData.append('fileName', fileBody.name);
      formData.append('fileBody', fileBody);

      let request_header = {
        email: this.userID,
        token: this.token,
      };
      // console.log(request_header)
      let request_json = formData;

      let request_url = '/requestUploadFile';
      const res = await this.requestPostFile(request_header, request_json, request_url);
      if (res.statusCode == 1) {
        // console.log('haole')
        return res.data.filePath;
        // this.pictureUrl = res.data.filePath;
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Upload file failed: ' + res.statusInfo)
        return ""
      }
    },

    handleDeleteDoc(ID, Name) {
      this.deleteDialog = true;
      this.docDeleteID = ID;
      this.docDeleteName = Name;
    },
    
    async handleSureDelete() {
      this.sureDeleteloading = true;
      
      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'docID': this.docDeleteID,
      };

      let request_url = '/requestDeleteFile';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.sureDeleteloading = false;
        this.deleteDialog = false;
        this.handleGetAllDoc();

        this.$store.commit('HandleADecisionSnackbar', 'Success to delete the document')
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to delete the document: ' + res.statusInfo)
        this.SureDeleteloading = false;
      }
    },

    async requestPostFile(request_header, request_json, url) {
      let baseURL=getBaseURL()
      let requestUrl =`${baseURL}${url}`
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: request_header,
        body: request_json
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8")

      const { value } = await reader.read();
      let decodedValue = decoder.decode(value);
      let realValue = JSON.parse(decodedValue)
      return realValue
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  }
};
</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:is(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>