<template>
  <v-container>
    <div v-if="decisionStatus.analyse">
      <div class="d-flex align-center">
        <span class="mr-4 my-4 text-h6 font-weight-black">PREFERENCE</span>
        <v-divider></v-divider>
      </div>
      <div class="d-flex">
        <v-tabs
          active-class="selected-tab"
          color="#439798"
          slider-size="4"
          v-model="analysePreferenceTabSelect"
          align-with-title
        >
          <v-tabs-slider color="#439798"></v-tabs-slider>

          <v-tab
            v-for="item in analysePreferenceTabItems"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </div>

      <v-divider></v-divider>

      <div v-if="analysePreferenceTabSelect == 0">
        <AnalysePreferenceValues />
      </div>
      <div v-else-if="analysePreferenceTabSelect == 1">
        <AnalysePreferenceCriteria />
      </div>
      <!-- <div v-else-if="analysePreferenceTabSelect == 2">
        <AnalysePreferenceEffects />
      </div> -->
    </div>
    <div v-else class="d-flex justify-center">
      <v-btn color="#439798" text class="text-capitalize text-body-1 font-weight-bold" readonly>
        Please complete the above operations
      </v-btn>
    </div>
  </v-container>
</template>
  
<script>
import AnalysePreferenceValues from '@/components/ProjectComponents/AnalyseComponents/AnalysePreferenceValues.vue'
import AnalysePreferenceCriteria from '@/components/ProjectComponents/AnalyseComponents/AnalysePreferenceCriteria.vue'
// import AnalysePreferenceEffects from '@/components/ProjectComponents/AnalyseComponents/AnalysePreferenceEffects.vue'

export default {
  name: 'ProjectAnalysePreference',

  components: {
    AnalysePreferenceValues,
    AnalysePreferenceCriteria,
    // AnalysePreferenceEffects,
  },

  data() {
    return {
      analysePreferenceTabSelect: null,
      // analysePreferenceTabItems: [
      //   'Values', 'Criteria', 'Marginal effects'
      // ],
      analysePreferenceTabItems: [
        'Values', 'Criteria'
      ],

      decisionStatus: {},
    }
  },

  created() {
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});

  },

  mounted() {

  },
  
  methods: {
    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },
  }
};
</script>

<style scoped>
.selected-tab {
  color: white; 
  background-color: #67c9cb;
}
</style>