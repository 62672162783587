<template>
  <v-app>
    <YA_Snackbar ref="YA_SnackbarRef" />
    <TopView />
    <YA_Loading />


    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import YA_Snackbar from '@/components/SocialComponents/YA_Snackbar.vue';
import YA_Loading from '@/components/SocialComponents/YA_Loading.vue';
import TopView from '@/views/SocialView/TopView.vue';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'

export default {
  name: 'App',

  components: {
    YA_Snackbar,
    YA_Loading,
    TopView,
  },

  data() {
    return {
    }
  },

  created() {
    this.$store.watch(
      (state) => state.systemMsg.ADecisionSnackbar, () => {
        if (this.$store.getters.getADecisionSnackbar !== '') {
          this.$refs.YA_SnackbarRef.handleSnackbar(this.$store.getters.getADecisionSnackbar);
          this.$store.commit('HandleADecisionSnackbar', '');
        }
      }, {
        deep: true
      }
    );
  },

  mounted() {
    if (getLocalStorage('ADecisionProjectID')) {
      if (!this.$router.currentRoute.fullPath.startsWith('/Project')) {
        let routerName = "ProjectMsg"
        if (this.$router.currentRoute.path !== '/' + routerName) {
          this.$router.push('/' + routerName);
        }
      }
    }
  },

  methods: {
    handleAppSnackbar() {
      this.$refs.YA_SnackbarRef.handleSnackbar('Failed to retrieve user information:');
    }
  }
};
</script>
