<template>
    <div>
      FilesView
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'FilesView',
  
    components: {
    },
  
    data() {
      return {
      }
    },
  
    methods: {
    }
  };
  </script>
  