<template>
  <div style="width: 100%; height: 100%;" class="d-flex py-3" ref="myComponent">
    <v-tooltip top v-for="(item, index) in data" :key="index" >
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
          :style="{ width: item.value * 100 * onePX + 'px' }"
          :color="item.color"
          v-bind="attrs" v-on="on"
        >
          <v-divider vertical color="white" v-if="item.value > 0"></v-divider>

        </v-sheet>
      </template>
      <span>[{{ (item.value * 100).toFixed(2) }}%] {{ item.name }} : {{ item.content }}</span>
    </v-tooltip>
  </div>
</template>
  
<script>

export default {
  name: 'YA_AlternativesProgress',

  props: {
    data: Array,
  },

  components: {
    
  },

  data() {
    return {
      onePX: 0,
    }
  },

  created() {
  },

  mounted() {
    this.$nextTick(()=> {
      this.onePX = this.$refs.myComponent.offsetWidth / 100;
    })
  },
  
  methods: {
  }
};
</script>

<style scoped>
</style>