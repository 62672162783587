<template>
  <v-container>
    <div>
      <div class="d-flex align-center">
        <span class="mr-4 my-4 text-h6 font-weight-black">ALTERNATIVES</span>
        <v-divider></v-divider>
      </div>
      <div class="ml-4 mb-4 d-flex">
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text" small :disabled="isModifying" @click="handleAlternativesModify">
          modify
          <v-icon size="20" class="ml-1" color="white">mdi-rename-outline</v-icon>
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small>
          <v-icon size="20" class="mr-1" color="white">mdi-robot-outline</v-icon>
          Generated by AI
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleAddAlternativesItem">
          <v-icon size="20" class="mr-1" color="white">mdi-plus</v-icon>
          New Alternative
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleSaveModify" :loading="modifyLoading">
          save
          <v-icon size="20" class="ml-1" color="white">mdi-content-save-edit-outline</v-icon>
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleCancelModify">
          cancel
          <v-icon size="20" class="ml-1" color="white">mdi-cancel</v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
    </div>

    <div class="mt-4">
      <v-card elevation="3">
        <v-card-title>
          Alternative List
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="alternativesDataTable"
          sort-by="calories"
          class="elevation-1"
          fixed-header
        >
          <template v-slot:[`item.alternativeName`]="{ item }">
            <span class="text-body-1 font-weight-black">{{ item.alternativeName }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
              small
              class="mr-2"
              :disabled="!isModifying"
              @click="handleEditItem(item, index)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              :disabled="!isModifying"
              @click="handleDeleteItem(item, index)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleAddAlternativesItem">
              <v-icon size="20" class="mr-1" color="white">mdi-plus</v-icon>
              New Alternative
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-dialog
      v-model="alternativesDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="#439798"
          height="40"
        >
          <v-btn icon dark small class="mx-1" @click="alternativesDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="handleConfirmModify">
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container>
          <div class="d-flex align-center mt-2">
            <span class="">ALTERNATIVE MESSAGE</span>
            <v-divider class="ml-2"></v-divider>
          </div>
          <div class="mt-2">
            <span class="text-body-2 ml-4">Alternative Name</span>
            <span class="text-body-2 red--text mx-2">*</span>
            <v-textarea
              v-model="editName"
              class="mx-2 mt-2"
              rows="1"
              dense
              solo
              label="Project Name"
            ></v-textarea>
          </div>
          <div>
            <span class="text-body-2 ml-4">Alternative Description</span>
            <!-- <span class="text-body-2 red--text mx-2">*</span> -->
            <v-textarea
              v-model="editDescription"
              class="mx-2 mt-2"
              counter
              rows="5"
              solo
              label="Project Description"
            ></v-textarea>
          </div>

          <div class="d-flex align-center mt-2">
            <span class="">CRITERIA MESSAGE</span>
            <v-divider class="ml-2"></v-divider>
          </div>
          <div v-for="(item, index) in criteriaDataTable" :key="index">
            <span class="text-body-2 ml-4">{{ item.Criteria }}</span>
            <!-- <span class="text-body-2 red--text mx-2">*</span> -->
            <v-select
              v-model="editCriteriaChoice[item.Criteria]"
              :items="item.Levels"
              class="mx-2 mt-2"
              label="Solo field"
              dense
              solo
            ></v-select>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Are you sure to delete {{ alternativeDeleteName }} ?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="deleteDialog = false"
            class='text-capitalize font-weight-bold'
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureDelete"
            :loading="sureDeleteloading"
            class='text-capitalize white--text font-weight-bold'
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'ProjectAlternatives',

  components: {
  },

  data() {
    return {
      isLogin: false,
      userID: '',
      token: '',

      isModifying: false,
      modifyLoading: false,

      alternativesDialog: false,
      editMode: undefined,
      editName: '',
      editDescription: '',
      editCriteriaChoice: {},

      deleteDialog: false,
      alternativeDeleteIndex: undefined,
      alternativeDeleteName: '',
      sureDeleteloading: false,

      headers: [],
      desserts: [],

      projectItemMain: {},
      criteriaItems: [],
      alternativesItems: [],
      decisionStatus: {},
    }
  },

  watch: {
  },

  computed: {
    criteriaDataTable() {
      let returnTags = []
      let midItem = {}
      this.criteriaItems.forEach((criteriaItem, index) => {
        midItem.Criteria = criteriaItem.criteriaTitle;
        midItem.Levels = criteriaItem.criteriaLevelItems.map(obj => obj.content)
        returnTags.push(midItem);
        midItem = {}
      })
      return returnTags
    },
    alternativesDataTable() {
      let returnTags = []
      let midItem = {}
      this.alternativesItems.forEach((alternativeItem, index) => {
        midItem = {};
        midItem.alternativeName = alternativeItem.alternativeName
        Object.entries(alternativeItem.alternativeCriteriaChoice).forEach(([key, value]) => {
          midItem[key] = value
        })
        returnTags.push(midItem);
      })
      return returnTags
    },
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.criteriaItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
        this.alternativesItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAlternatives || []);
        this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});

        this.initCriteria()
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.criteriaItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
    this.alternativesItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAlternatives || []);
    this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});

    this.initCriteria()
  },

  mounted() {

  },
  
  methods: {
    initCriteria() {
      this.headers = [
        {
          text: 'name',
          align: 'start',
          sortable: false,
          value: 'alternativeName',
          width: 200,
        },
        { text: 'Actions', value: 'actions', sortable: false, width: 88 },
      ]
      this.criteriaItems.forEach((criteriaItem, index) => {
        let headerItem = {
          text: criteriaItem.criteriaTitle,
          value: criteriaItem.criteriaTitle
        }
        this.headers.push(headerItem)
      })
    },

    handleAddAlternativesItem() {
      this.editName = '';
      this.editDescription = '';
      this.criteriaItems.forEach((criteriaItem, index) => {
        this.editCriteriaChoice[criteriaItem.criteriaTitle] = undefined;
      })
      this.editMode = 'create';
      this.alternativesDialog = true;
    },

    handleEditItem(item, index) {
      this.editName = this.alternativesItems[index].alternativeName;
      this.editDescription = this.alternativesItems[index].alternativeDescription;
      this.editCriteriaChoice = { ...this.alternativesItems[index].alternativeCriteriaChoice };
      this.editMode = index;
      this.alternativesDialog = true;
    },

    handleDeleteItem(item, index) {
      this.alternativeDeleteIndex = index;
      this.alternativeDeleteName = item.alternativeName;
      this.deleteDialog = true;
    },

    handleSureDelete() {
      this.sureDeleteloading = true;
      this.alternativesItems.splice(this.alternativeDeleteIndex, 1);
      this.deleteDialog = false;
      this.sureDeleteloading = false;
    },

    handleAlternativesModify() {
      if (this.isLogin) {
        if (this.projectItemMain.projcetID == '') {
          this.$store.commit('HandleADecisionSnackbar', 'Please Wait')
        } else {
          this.isModifying = true;
        }
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Please Login')
      }
    },

    handleConfirmModify() {
      let criteriaChoice = {};
      this.criteriaItems.forEach((criteriaItem)=> {
        criteriaChoice[criteriaItem.criteriaTitle] = this.editCriteriaChoice[criteriaItem.criteriaTitle];
      })
      
      if (this.editName == '') {
        this.$store.commit('HandleADecisionSnackbar', 'Please enter alternative name')
      } else {
        let alternativeItem = {}
        if (this.editMode == 'create') {
          alternativeItem = {
            alternativeName: this.editName,
            alternativeDescription: this.editDescription,
            alternativeCriteriaChoice: criteriaChoice,
          }
          this.alternativesItems.push(alternativeItem)
        } else {
          alternativeItem = {
            alternativeName: this.editName,
            alternativeDescription: this.editDescription,
            alternativeCriteriaChoice: criteriaChoice,
          }
          this.$set(this.alternativesItems, this.editMode, alternativeItem);
        }
        this.alternativesDialog = false;
      }
    },

    async handleSaveModify() {
      this.$store.commit('HandleloadingMsg', "alternative modified loading");
      this.modifyLoading = true;

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': this.$store.getters.getprojectItemMain.projectID,
        'decisionAlternatives': JSON.stringify(this.alternativesItems),
        'decisionStatus': JSON.stringify(this.handleMakeDecisionStatus()),
        'decisionLastChangeTime': Date.now(),
      };
      
      let request_url = '/requestModifyAlternatives';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.modifyLoading = false;
        this.$store.commit('HandleloadingMsg', "");
        this.$store.commit('HandleADecisionSnackbar', 'Success to modify');
        this.isModifying = false;

        this.$store.commit('HandlerefreshSingleProject', true);
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to create project:' + res.statusInfo);
      }
    },

    handleCancelModify() {
      this.isModifying = false;
      this.$store.commit('HandlerefreshSingleProject', true);
    },

    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },

    handleMakeDecisionStatus() {
      let status = this.decisionStatus
      if (this.alternativesItems.length > 0) {
        status.alternatives = true;
        if (status.tradeOff) {
          status.analyse = true;
        } else {
          status.analyse = false;
        }
      } else {
        status.alternatives = false;
        status.analyse = false;
      }
      return status
    }
  }
};
</script>

<style scoped>

</style>