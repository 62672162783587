<template>
  <v-container>
    <div v-if="hasCriterias">
      <div class="d-flex align-center">
        <span class="mr-4 my-4 text-h6 font-weight-black">TRADE OFF</span>
        <v-divider></v-divider>
      </div>
      <div class="ml-4 mb-4 d-flex">
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small @click="handleTradeOffReview">
          review & undo
          <v-icon size="20" class="ml-1" color="white">mdi-content-save-edit-outline</v-icon>
        </v-btn>
        
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small @click="handleTradeOffSelect">
          <div class="mx-4 d-flex align-center">
            <span>begin</span>
            <v-icon size="20" class="ml-1" color="white">mdi-arrow-right-bold-box-outline</v-icon>
          </div>
        </v-btn>
      </div>
      <v-divider></v-divider>

      <keep-alive>
        <router-view />
      </keep-alive>
    </div>

    <div v-else class="d-flex justify-center">
      <v-btn color="#439798" text class="text-capitalize text-body-1 font-weight-bold" @click="handleCriteria">
        Create New Citeria Now
      </v-btn>
    </div>

    
  </v-container>
</template>
  
<script>
export default {
  name: 'ProjectTradeOff',

  components: {
  },

  data() {
    return {
      tradeOffItems: [],
      projectItemMain: {},

      isLogin: false,
      userID: '',
      token: '',
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.tradeOffItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionTradeOff || []);
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.tradeOffItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionTradeOff || []);
  },

  mounted() {

  },

  computed: {
    tradeOffNumber() {
      return this.tradeOffItems.length
    },

    tradeOffCurrentNum() {
      let num = 0
      this.tradeOffItems.forEach((item, index)=> {
        if (item.isFinished) {
          num += 1
        }
      })
      
      return Math.floor((num / this.tradeOffNumber) * 100)
    },

    hasCriterias() {
      let result = false;
      if (this.tradeOffItems.length > 0) {
        result = true;
      } else {
        result = false;
      }
      return result
    },
  },
  
  methods: {
    handleTradeOffReview() {
      let routerName = "Project/ProjectTradeOff/ProjectReviewTradeOff"
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },

    handleTradeOffSelect() {
      let routerName = "Project/ProjectTradeOff/ProjectSelectTradeOff"
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },

    handleCriteria() {
      let routerName = 'Project/ProjectCriteria'
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },

    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },
  }
};
</script>

<style scoped>

</style>