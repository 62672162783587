<template>
  <div>
    <LeftBarProject />
  
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
  
<script>
import LeftBarProject from '@/components/LeftBarComponents/LeftBarProject.vue';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

  
export default {
  name: 'ProjectMainView',
  
  components: {
    LeftBarProject,
  },
  
  data() {
    return {
      projectID: "",

      isLogin: false,
      userID: '',
      token: '',
    }
  },
  
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;

    this.$store.watch(
      (state) => state.projectMsg.refreshSingleProject, () => {
        if (this.$store.getters.getrefreshSingleProject) {
          this.handleGetSingleProject();
          this.$store.commit('HandlerefreshSingleProject', false);
        }
      }, {
        deep: true
      }
    );

    this.projectID = getLocalStorage('ADecisionProjectID');
  },
  
  mounted() {
    this.handleGetSingleProject();
  },

  methods: {
    async handleGetSingleProject() {
      this.$store.commit('HandleloadingMsg', "project loading");

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': getLocalStorage('ADecisionProjectID')
      };

      let request_url = '/requestGetSingleProject';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        res.data.forEach(item => {
          item.createTime = this.formatDate(parseInt(item.createTime))
          item.decisionLastChangeTime = this.formatDate(parseInt(item.decisionLastChangeTime))
          item.executionLastChangeTime = this.formatDate(parseInt(item.executionLastChangeTime))
        });
        this.$store.commit('HandleprojectItemMain', res.data[0]);

        let routerPath = "/" + res.data[0].projectName + "/" + this.$router.currentRoute.name.substring(7)
        this.$store.commit('HandlerouterPath', routerPath);

        this.$store.commit('HandleloadingMsg', "");
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to get project:' + res.statusInfo);
      }
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  }
};
</script>
  