<template>
  <div class="d-flex flex-column">
    <YA_ScrollComponent ref="ScrollComRef" :scrollHeight="130" v-scroll.self="onScroll">
      <div class="mb-4">
        <v-container>
          <p class="blue-grey--text text--darken-4 text-center text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black my-4">ADecision</p>
          <p class="blue-grey--text text-center mb-8">This is a part of AI Decision Talking</p>

          <div>
            <v-row class="" v-for="(item, index) in chatContent" :key="index" :ref="'row' + index">
              <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card elevation="2">
                  <div class="grey lighten-4">
                    <v-row no-gutters>
                      <v-col cols="1" class="">
                        <p class="mx-4 mt-4"><v-icon color='teal darken-3'>mdi-account</v-icon></p>
                      </v-col>
                      <v-col cols="11" class="">
                        <p class="mx-4 mt-4">{{ item.question }}</p>
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider></v-divider>
                  <v-progress-linear color="teal accent-4" indeterminate rounded height="6" :active="!item.isDone"></v-progress-linear>

                  <div>
                    <v-row no-gutters>
                      <v-col cols="1" class="">
                        <p class="mx-4 mt-4"><v-icon color='teal darken-3'>mdi-robot</v-icon></p>
                      </v-col>
                      
                      <v-col cols="11" class="">
                        <div v-html="htmlfy(item.answer)" class="mx-4 mt-4" style="white-space: pre-line;"></div>
                      </v-col>

                    </v-row>
                    <!-- <v-divider></v-divider>
                    <div v-html="item.note" class="px-5 py-5 text-center" style="white-space: pre-line;"></div> -->
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </div>
    </YA_ScrollComponent>

    <v-footer height="90" width="100%" color="transparent">

    </v-footer>
    <div class="mx-2" style="position: relative;">
      <v-textarea 
        style="position: absolute; bottom: 0; left: 0; right: 0; background-color: white;"
        ref='textareaQuestion'
        @keypress.enter.exact="handleAIAnalyse"
        label="Talk to ADecision..."
        auto-grow
        color="#439798"
        rows="1"
        v-model='AIAnalyseQuestion'
        :loading='AIAnalyseLoading'
        outlined
        :disabled="AIAnalyseIsRunning"
        prepend-inner-icon='mdi-magnify'
      >
        <template v-slot:prepend>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mt-n1" color="secondary" fab x-small v-bind="attrs" v-on="on" @click="handleAIAnalyseClear">
                <v-icon>mdi-brush-variant</v-icon>
              </v-btn>
            </template>
            <span>Clear</span>
          </v-tooltip>
        </template>

        <template v-slot:append>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show='!AIAnalyseIsRunning' class="mt-n1" color="#439798" fab x-small v-bind="attrs" v-on="on" @click="handleAIAnalyse">
                <v-icon color="white">mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Send</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-show='AIAnalyseIsRunning' class="error mt-n1" fab x-small v-bind="attrs" v-on="on" @click="handleAIAnalyseStop">
                <v-icon>mdi-stop</v-icon>
              </v-btn>
            </template>
            <span>Stop</span>
          </v-tooltip>
        </template>
      </v-textarea>
    </div>
  </div>
</template>
  
<script>
import YA_ScrollComponent from '@/components/SocialComponents/YA_ScrollComponent.vue';
import DOMPurify from 'dompurify';
// import { getDid } from '@/utils/SystemUtils/fingerprint.js'
import { getToken } from '@/utils/SystemUtils/token.js'
import { getBaseURL } from '@/utils/SystemUtils/url.js'
import { getCookie, setCookie, removeCookie } from '@/utils/SystemUtils/cookies.js'
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { createHash } from 'crypto-browserify';
import { getDid } from '@/utils/SystemUtils/fingerprint.js'

import { marked } from 'marked';
import { mangle } from "marked-mangle";
import { gfmHeadingId } from "marked-gfm-heading-id";
import markedLinkifyIt from "marked-linkify-it";
import markedKatex from "marked-katex-extension";
import 'katex/dist/katex.min.css';

export default {
  name: 'AnalyseChat',

  components: {
    YA_ScrollComponent,
  },

  data() {
    return {
      AIAnalyseQuestion: '',
      AIAnalyseLoading: false,
      AIAnalyseIsRunning: false,
      enableScroll:true,

      isLogin: false,
      userID: '',
      token: '',

      projectItemMain: {},
      decisionAnalyse: {},
      decisionAlternatives: [],
      decisionCriteria: [],

      chatContent: [
        // {
        //   question: 'asdfasdfsadfasdfasdfdsadfasdavnalvkrmopewkjmlksanvladfasdavnalvkrmopewkjmlksanvladfasdavnalvkrmopewkjmlksanvlkmwaelmefldmdsa.cmz,asdfkasjdklnbgoksdj',
        //   answer: 'fasdfasdf',
        //   isDone: true,
        //   note: 'lalalla',
        // },
        // {
        //   question: '好好吃饭',
        //   answer: 'fasdfasdf',
        //   isDone: true,
        //   note: 'lalalla',
        // },
        // {
        //   question: 'sljdfaif',
        //   answer: 'fasdfasdf',
        //   isDone: false,
        //   note: 'lalalla',
        // },
        // {
        //   question: 'sljdfaif',
        //   answer: 'fasdfasdf',
        //   isDone: true,
        //   note: 'lalalla',
        // },
        // {
        //   question: 'sljdfaif',
        //   answer: 'fasdfasdf',
        //   isDone: true,
        //   note: 'lalalla',
        // },
      ],

    }
  },

  computed: {
  },

  created() {
    removeLocalStorage('ADecisionChatlist')

    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.decisionAnalyse = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAnalyse || {});
        this.decisionAlternatives = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAlternatives || []);
        this.decisionCriteria = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.decisionAnalyse = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAnalyse || {});
    this.decisionAlternatives = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionAlternatives || []);
    this.decisionCriteria = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
  },

  mounted() {
    let did = getDid().then(res => {
      setCookie('did', res)
      setLocalStorage('did', res)
    });
    let lastSession=Date.now()
    setCookie('lastSession', lastSession)

    // for (let key in this.chatContent) {
    //   removeLocalStorage(key + 'chatlist')
    // }
    const localstoragekeys = Object.keys(localStorage);

    localstoragekeys.forEach(function(key) {
      if (key.endsWith("chatlist")) {
        removeLocalStorage(key);
      }
    });

    marked.use(mangle());
    marked.use(gfmHeadingId({prefix: "my-prefix-"}));
    marked.use(markedLinkifyIt());
    marked.use(markedKatex({throwOnError: false}));

    this.wheelEventHandler = this.disableScroll.bind(this);
    window.addEventListener('wheel', this.wheelEventHandler);
  },

  beforeDestroy() {
    window.removeEventListener('wheel', this.wheelEventHandler);

  },
  
  methods: {
    utoa(data) {
      return btoa(unescape(encodeURIComponent(data)));
    },
    atou(b64) {
      return decodeURIComponent(escape(atob(b64)));
    },
    htmlfy(answer) {
      // console.log(DOMPurify.sanitize(marked.parse(answer)))
      return DOMPurify.sanitize(marked.parse(answer));      
    },
    onScroll (e) {
      // this.pageSetNum = e.target.scrollTop / this.pageHeight;

      // console.log(e.target.scrollTop)
      // console.log(this.pageHeight)
      // console.log(this.pageSetNum)

      // this.offsetTop = e.target.scrollTop
      this.disableScroll();
    },
    disableScroll() {
      this.enableScroll = false;
    },
    scrollDown() {
      this.$refs.ScrollComRef.comScrollDown();
      // window.scrollTo(0, document.body.scrollHeight);
    },
    scrollScreen() {
      this.$nextTick(() => {
        if(this.enableScroll){
          this.$refs.ScrollComRef.scrollScreen();
        }  
      })
    },
    focus(){
      // focus text-area
      this.$nextTick(() => {
        // console.log(this.$refs.textareaQuestion)
        this.$refs.textareaQuestion.focus();
      })
    },

    async handleAIAnalyse() {
      if (this.isLogin) {
        event.preventDefault();
        let question = this.AIAnalyseQuestion.trim();
        if (question.length > 10000) {
          this.$store.commit('HandleADecisionSnackbar', 'The length of your question is too long')
        } else {
          if (question.length > 0) {
            this.enableScroll=true
            let onechat = {
              'question': this.AIAnalyseQuestion,
              'answer': ``,
              'isDone': false,
              'note':''
            }
            this.chatContent.push(onechat);

            this.scrollScreen();
            this.AIAnalyseQuestion = '';
            this.AIAnalyseIsRunning = true;
            this.AIAnalyseLoading = true;

            let response;
            try {
              response = await this.requestPowerChat(question)
            } catch (error) {
              console.log(error)
              this.try_error(error)
            } finally {
              this.try_finally(response)
            }
          }
        }
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Please Login')
      }
    },

    try_error(error) {
      this.chatContent[this.chatContent.length - 1]['answer'] += "<strong style='color:red'>Network error[2001],you can try again or notify us by sending an email to <a href='mailto:hello@promptboom.com'>hello@promptboom.com</a>. Thank you</strong>";
    },

    try_finally(response) {
      // if (response.status === 200) {
      //   this.$store.commit('HandleChatUseNum', this.ChatUseNum - 1);
      //   this.$store.commit('HandleNotLoginChatNum', this.NotLoginChatNum - 1);
      //   setNotLoginLimit();
      // }

      this.AIAnalyseIsRunning = false;
      this.AIAnalyseLoading = false;
      this.chatContent[this.chatContent.length - 1]['isDone'] = true;

      let tempAnswer = this.chatContent[this.chatContent.length - 1]['answer'];
      if (tempAnswer.length == 0) {
        this.chatContent[this.chatContent.length - 1]['answer'] = "<strong style='color:red'>Network error[2001],you can try again or notify us by sending an email to <a href='mailto:hello@promptboom.com'>hello@promptboom.com</a>. Thank you</strong>";
      }
      this.focus()
    },

    async requestPowerChat(question) {
      let chatList = []

      let lastSession=getCookie('lastSession')
      let currentSession=Date.now()
      if((currentSession-lastSession)>1000*60*20){
        setCookie('lastSession', currentSession)
        removeLocalStorage('ADecisionChatlist')
      }

      if (getLocalStorage('ADecisionChatlist')) {
        chatList = JSON.parse(getLocalStorage('ADecisionChatlist'))
      }

      chatList.push({
        "role": "user",
        "content": question
      })

      const t = Date.now()
      const token=getToken()
      const r = t + ":" + "question:" + token
      const sign = createHash('sha256').update(r).digest('hex')
      // console.log(this.decisionAnalyse.tradeOffRate);
      // console.log(this.decisionAnalyse);
      let request_json = {
        'did': getCookie('did'),
        'chatList': chatList,
        'botID': 'adecision',
        'requireMsg': {
          decisionAnalyse: JSON.stringify(this.decisionAnalyse.alternativeRate),
          decisionAlternatives: JSON.stringify(this.decisionAlternatives),
          decisionCriteria: JSON.stringify(this.decisionCriteria),
          projectPurpose: this.projectItemMain.projectPurpose,
          projectName: this.projectItemMain.projectName,
          question: question
        },
        'special': {
          'referer':document.referrer||'no-referer',
          'path':location.href
        }
      };

      let raw_requst_json = {
        'data': this.utoa(JSON.stringify(request_json))
      };

      let baseURL=getBaseURL()
      let requestUrl =`${baseURL}/requestPowerChat`

      const response = await fetch(requestUrl, {
        method: 'POST',
        headers:{
          email: this.userID,
          token: this.token,
          isProUser: this.$store.getters.getIsProUser
        },
        body: JSON.stringify(raw_requst_json)
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8")
      while (this.AIAnalyseIsRunning) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        let decodedValue = decoder.decode(value);
        this.chatContent[this.chatContent.length - 1]['answer']+=decodedValue;
        this.scrollScreen()
      }
      if (this.chatContent[this.chatContent.length - 1]['answer'].length > 0) {
        if (response.status === 200) {
          this.cacheChatList(question, this.chatContent[this.chatContent.length - 1]['answer']);
        }
        this.chatContent[this.chatContent.length - 1]['note']=`==<strong style='color:blue;text-align:center'>Powered by <a href='https://promptboom.com'>PromptBoom</a></strong>==`
      }
      return response
    },

    cacheChatList(question, answer) {
      let tempOneQuestion = {
        'role': 'user',
        'content': question
      }
      let tempOneAnswer = {
        'role': 'assistant',
        'content': answer
      }

      let localChatList = []

      if (getLocalStorage('ADecisionChatlist')) {
        localChatList = JSON.parse(getLocalStorage('ADecisionChatlist'));
      }
      localChatList.push(tempOneQuestion)
      localChatList.push(tempOneAnswer)
      if (localChatList.length > 20) {
        localChatList.shift()
        localChatList.shift()
      }
      setLocalStorage('ADecisionChatlist', JSON.stringify(localChatList))
    },

    handleAIAnalyseClear() {
      removeLocalStorage('ADecisionChatlist')
      this.chatContent = [];
    },

    handleAIAnalyseStop() {
      this.AIAnalyseIsRunning=false;
      this.AIAnalyseLoading = false;
    },

    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },
  }
};
</script>

<style scoped>

</style>

