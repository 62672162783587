<template>
  <v-navigation-drawer 
    width="240"
    app
    clipped 
    color="#fbfbfb"
    fixed
    v-model="leftBarTag"
    disable-resize-watcher
  >
    <div style="height: 100%;" class="d-flex flex-column">
      <div class="mx-3 pt-6">
        <div class="d-flex align-center">
          <v-icon color="blue">mdi-folder</v-icon>
          <span class="ml-2 text-body-1 font-weight-bold">{{ projectName }}</span>
        </div>
        <div class="d-flex align-center mt-2 ml-4">
          <v-icon color="orange">mdi-redo</v-icon>
          <span class="ml-4 text-body-2">{{ projectName + " Decision" }}</span>
        </div>
      </div>

      <v-container>
        <v-divider></v-divider>
      </v-container>

      <div>
        <v-timeline
          align-top
          dense
          class="ml-n6"
        >
          <div v-for="(ItemTag, index) in ItemTags" :key="index">
            <v-timeline-item fill-dot small :color="TimeItemColor[index] == 1 ? 'green' : 'orange'" class="my-n4">
              <v-list dense nav class="ml-n8 mt-n3">
                <v-list-item-group
                  v-model="ColectItem[index]"
                >
                  <v-list-group
                    color="#439798"
                    v-if="ItemTag.multiple"
                    :value="true"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>{{ ItemTag.name }}</v-list-item-title>
                    </template>
                    <v-list-item
                      v-for="([title, icon], i) in ItemTag.items"
                      :key="i"
                      color="#439798"
                      @click="handleMultipleItem(index, i)"
                    >
                      <v-list-item-title v-text="title" class="ml-4"></v-list-item-title>
                      <v-list-item-icon>
                        <v-icon v-text="icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list-group>

                  <v-list-item
                    v-else
                    color="#439798"
                    @click="handleItem(ItemTag.name)"
                  >
                    <v-list-item-title>{{ ItemTag.items[0][0] }}</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>{{ ItemTag.items[0][1] }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                </v-list-item-group>
              </v-list>
            </v-timeline-item>
          </div>
        </v-timeline>
      </div>

      <div class="mt-auto pa-4">
        <v-divider class="mb-3"></v-divider>

        <v-btn color="#439798" class="text-capitalize text-h6 white--text" block @click="handleQuitProject">
          <v-icon size="25" class="mr-1" color="white">mdi-arrow-left-bold-box-outline</v-icon>
          Quit project
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>
  
<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'LeftBarProject',

  components: {
  },

  watch: {
    leftBarTag(newValue, oldValue) {
      if (!newValue) {
          this.$store.commit('HandleleftBarTag', false);
      }
    }
  },

  data() {
    return {
      leftBarTag: true,

      projectItemMain: "",
      projectName: "",
      ColectItem: [undefined, undefined, undefined, undefined, undefined],

      ItemTags: [
        {
          name: "Files",
          multiple: true,
          items: [
            ['Project Message', 'mdi-information-outline'],
            ['Project Files', 'mdi-file-document-multiple-outline'],
          ],
        },
        {
          name: "Criteria",
          multiple: false,
          items: [
            ['Evaluation Criteria', 'mdi-tag-check-outline'],
          ],
        },
        {
          name: "Alternatives",
          multiple: false,
          items: [
            ['Alternatives', 'mdi-format-list-bulleted-square'],
          ],
        },
        {
          name: "Trade-Off",
          multiple: false,
          items: [
            ['Trade-Off', 'mdi-head-cog-outline'],
          ],
        },
        {
          name: "Analyse",
          multiple: true,
          items: [
            ['Summary', 'mdi-tablet-dashboard'],
            ['Preference', 'mdi-chart-bar-stacked'],
            ['Alternatives', 'mdi-vector-point-select'],
          ],
        },
      ],
      // TimeItemColor: [1, 1, 1, 0, 0],

      decisionStatus: {},
    }
  },

  created() {
    this.$store.watch(
      (state) => state.systemMsg.leftBarTag, () => {
        this.leftBarTag = this.$store.getters.getleftBarTag;
      }, {
        deep: true
      }
    );
    this.leftBarTag = this.$store.getters.getleftBarTag;

    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.projectName = this.$store.getters.getprojectItemMain.projectName;
        this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.projectName = this.$store.getters.getprojectItemMain.projectName;
    this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});

    this.$store.watch(
      (state) => state.systemMsg.routerPath, () => {
        this.handleFocus();
      }, {
        deep: true
      }
    );
    this.handleFocus();
  },

  computed: {
    TimeItemColor() {
      return Object.values(this.decisionStatus).map(value => value ? 1 : 0);
    },
  },

  methods: {
    handleQuitProject() {
      removeLocalStorage("ADecisionProjectID")
      this.$store.commit('HandleprojectItemMain', {});
      let routerName = "Home/Project"
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },
    handleFocus() {
      if (this.$router.currentRoute.name.substring(7) == 'Msg') {
        this.ColectItem = [1, undefined, undefined, undefined, undefined]
      } else if (this.$router.currentRoute.name.substring(7) == 'Files') {
        this.ColectItem = [2, undefined, undefined, undefined, undefined]
      } else if (this.$router.currentRoute.name.substring(7) == 'Criteria') {
        this.ColectItem = [undefined, 0, undefined, undefined, undefined]
      } else if (this.$router.currentRoute.name.substring(7) == 'Alternatives') {
        this.ColectItem = [undefined, undefined, 0, undefined, undefined]
      } else if (this.$router.currentRoute.name.substring(13) == 'TradeOff') {
        this.ColectItem = [undefined, undefined, undefined, 0, undefined]
      } else if (this.$router.currentRoute.name.substring(7) == 'AnalyseSummary') {
        this.ColectItem = [undefined, undefined, undefined, undefined, 1]
      } else if (this.$router.currentRoute.name.substring(7) == 'AnalysePreference') {
        this.ColectItem = [undefined, undefined, undefined, undefined, 2]
      } else if (this.$router.currentRoute.name.substring(7) == 'AnalyseAlternatives') {
        this.ColectItem = [undefined, undefined, undefined, undefined, 3]
      }
    },
    handleMultipleItem(index, i) {
      let routerName = ''
      if (index == 0) {
        if (i) {
          routerName = 'Project/ProjectFiles'
        } else {
          routerName = 'Project/ProjectMsg'
        }
      } else if (index == 4) {
        if (i == 0) {
          routerName = 'Project/ProjectAnalyseSummary'
        } else if (i == 1) {
          routerName = 'Project/ProjectAnalysePreference'
        } else if (i == 2) {
          routerName = 'Project/ProjectAnalyseAlternatives'
        }
      }
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },
    handleItem(name) {
      let routerName = ''
      if (name == "Criteria") {
        routerName = 'Project/ProjectCriteria'
      } else if (name == "Alternatives") {
        routerName = 'Project/ProjectAlternatives'
      } else if (name == "Trade-Off") {
        routerName = 'Project/ProjectTradeOff'
      }
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },

    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },
  }
};
</script>

<style scoped>

</style>