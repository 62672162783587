<template>
  <div>
    <div v-if="decisionStatus.analyse">
      <v-row no-gutters>
        <v-col cols="6">
          <AnalyseSummaryResult />
        </v-col>
        <v-col cols="6">
          <AnalyseChat />
        </v-col>
      </v-row>
    </div>
    <v-container v-else class="d-flex justify-center">
      <v-btn color="#439798" text class="text-capitalize text-body-1 font-weight-bold" readonly>
        Please complete the above operations
      </v-btn>
    </v-container>
  </div>
</template>
  
<script>
import Chart from 'chart.js/auto';
import AnalyseChat from '@/components/ProjectComponents/AnalyseComponents/AnalyseChat.vue';
import AnalyseSummaryResult from '@/components/ProjectComponents/AnalyseComponents/AnalyseSummaryResult.vue';


export default {
  name: 'ProjectAnalyseSummary',

  components: {
    AnalyseChat,
    AnalyseSummaryResult,
  },

  data() {
    return {
      decisionStatus: {},
    }
  },

  created() {
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});

  },

  mounted() {
  },
  
  methods: {
    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },
  }
};
</script>

<style scoped>

</style>

