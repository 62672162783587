<template>
  <v-container>
    <div>
      <div class="d-flex align-center">
        <span class="ma-4">QUICK START</span>
        <v-divider></v-divider>
      </div>

      <div class="ml-4 d-flex">
        <v-card
          v-for="(item, index) in QuickStartItems"
          :key="index"
          class="d-flex justify-center align-center mr-4"
          max-width="140"
          min-height="140"
          outlined
          elevation="1"
          @click="handleQuickStart(item.name)"
        >
          <v-container class="d-flex justify-center flex-wrap align-center">
            <v-icon
              :color="item.iconColor"
              size="56"
            >
              {{ item.icon }}
            </v-icon>
            <span class="mt-2">{{ item.name }}</span>
          </v-container>
        </v-card>
      </div>
    </div>

    <div class="mt-4">
      <div class="d-flex align-center">
        <span class="ma-4">TRY DEMO</span>
        <v-divider></v-divider>
      </div>

      <div class="ml-4">
        <div class="d-flex justify-center mt-6" v-if="getProjectDemoLoading">
          <v-progress-circular
            color="#296162"
            indeterminate
            size="48"
          ></v-progress-circular>
        </div>
        <div class="d-flex" v-else>
          <v-card
            v-for="(item, index) in TryDemoItemsComputed"
            :key="index"
            class="mr-4 d-flex flex-column"
            width="240"
            height="120"
            outlined
            elevation="1"
            @click="handleTryDemo(item.id)"
          >
            <div class="d-flex justify-space-between align-center mx-2 mb-2 mt-1">
              <div>
                <v-chip x-small label color="orange" text-color="white" v-if="item.type=='Decision'">Decision</v-chip>
                <v-chip x-small label color="green" text-color="white" v-else-if="item.type=='Execution'">Execution</v-chip>
                <v-chip x-small label color="blue" text-color="white" v-else-if="item.type=='Project'">Project</v-chip>
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="dark-grey" x-small>
                    <v-icon>mdi-information-variant</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.msg }}</span>
              </v-tooltip>
              
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div class="ml-2">
                  <span v-on="on" class="text-body-1 font-weight-bold">{{ item.newName }}</span>
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
            
            <div class="mt-auto ml-2">
              <span class="text-body-2 grey--text font-weight-bold">{{ item.time }}</span>

            </div>
          </v-card>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="d-flex align-center">
        <span class="ma-4">ALL ITEMS</span>
        <v-divider></v-divider>
      </div>

      <div class="d-flex justify-center" v-if="!isLogin">
        <span class="text-body-1 font-weight-bold mt-4">Please Login</span>
      </div>
      <div v-else>
        <div class="d-flex justify-center mt-6" v-if="getProjectLoading">
          <v-progress-circular
            color="#296162"
            indeterminate
            size="48"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="ml-4" v-if="projectItems.length > 0">
            <v-expansion-panels flat outlined accordion hover>
              <v-expansion-panel
                v-for="(projectItem, index) in projectItems"
                :key="index"
                @mouseenter.native="projectItem.showButtons = true"
                @mouseleave.native="projectItem.showButtons = false"
              >
                <v-divider v-if="index != 0"></v-divider>
                <v-expansion-panel-header>
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center">
                      <v-icon color="blue">mdi-folder</v-icon>
                      <span class="ml-2 text-body-1 font-weight-bold">{{ projectItem.projectName }}</span>
                    </div>
                    <div class="mr-6 d-flex align-center">
                      <v-tooltip bottom v-if="projectItem.showButtons">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="dark-grey" x-small class="mr-3" @click.stop="handleDeleteProject(projectItem.projectID, projectItem.projectName)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="projectItem.showButtons">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon color="dark-grey" x-small @click.stop="">
                            <v-icon>mdi-information-variant</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ projectItem.projectDescription }}</span>
                      </v-tooltip>
                      <span class="text-body-2 grey--text font-weight-bold mx-4">{{ projectItem.createTime }}</span>
                    </div>
                  </div>
                  
                  <template v-slot:actions>
                    <v-icon color="primary">
                      $expand
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense nav>
                    <div class="ml-4" v-if="projectItem.hasDecision">
                      <v-list-item link @click="handleEnterProjectDecision(projectItem)">
                        <v-list-item-content>
                          <div class="d-flex align-center">
                            <v-icon color="orange">mdi-redo</v-icon>
                            <span class="ml-4 text-body-2">{{ projectItem.projectName }} Decision</span>
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="mr-6 d-flex align-center">
                            <span class="text-body-2 grey--text font-weight-bold">{{ projectItem.decisionLastChangeTime }}</span>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </div>
                    <div class="ml-4" v-if="projectItem.hasExecution">
                      <v-divider></v-divider>
                      <v-list-item link @click="handleEnterProjectExecution(projectItem)">
                        <v-list-item-content>
                          <div class="d-flex align-center">
                            <v-icon color="green">mdi-autorenew</v-icon>
                            <span class="ml-4 text-body-2">{{ projectItem.projectName }} Execution</span>
                          </div>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="mr-6 d-flex align-center">
                            <span class="text-body-2 grey--text font-weight-bold">{{ projectItem.executionLastChangeTime }}</span>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </div>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div class="d-flex justify-center" v-else>
            <v-btn color="#439798" text class="text-capitalize text-body-1 font-weight-bold" @click="handleQuickStart('New project')">
              Create New Project Now
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="createDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="#439798"
          height="40"
        >
          <v-btn icon dark small class="mx-1" @click="handleCancelCreate">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">{{ createHeader }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text :loading="createLoading" @click="handleCreateProject">
              Create
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container>
          <div class="d-flex align-center mt-2">
            <span class="">PROJECT MESSAGE</span>
            <v-divider class="ml-2"></v-divider>
          </div>
          <div class="mt-2">
            <span class="text-body-2 ml-4">Project Name</span>
            <span class="text-body-2 red--text mx-2">*</span>
            <v-textarea
              v-model="projectName"
              class="mx-2 mt-2"
              rows="1"
              dense
              solo
              label="Project Name"
            ></v-textarea>
          </div>
          <div>
            <span class="text-body-2 ml-4">Project Description</span>
            <span class="text-body-2 red--text mx-2">*</span>
            <v-textarea
              v-model="projectDescription"
              class="mx-2 mt-2"
              counter
              rows="5"
              solo
              label="Project Description"
            ></v-textarea>
          </div>
          <div>
            <span class="text-body-2 ml-4">Project Purpose</span>
            <span class="text-body-2 red--text mx-2">*</span>
            <v-textarea
              v-model="projectPurpose"
              class="mx-2 mt-2"
              counter
              rows="5"
              solo
              label="Project Description"
            ></v-textarea>
          </div>

        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Are you sure to delete {{ projectDeleteName }} ?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="deleteDialog = false"
            class='text-capitalize font-weight-bold'
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureDelete"
            :loading="sureDeleteloading"
            class='text-capitalize white--text font-weight-bold'
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';


export default {
  name: 'HomeView',

  components: {
  },

  data() {
    return {
      QuickStartItems: [
        {
          name: "New project",
          iconColor: "#439798",
          icon: "mdi-folder-plus",
        },
        {
          name: "New decision",
          iconColor: "#439798",
          icon: "mdi-redo",
        },
        {
          name: "New execution",
          iconColor: "#439798",
          icon: "mdi-autorenew",
        },
      ],
      TryDemoItems: [
        // {
        //   id: "0001",
        //   name: "What target to decision asdfljasldfnsoakdnglkjsdlansdkfjsdl",
        //   time: "2024-03-02 12:00:00",
        //   type: "Decision",
        //   msg: "this is a target to decision.",
        // },
        // {
        //   id: "0002",
        //   name: "What target to decision asdfljasldfnsoakdnglkjsdlansdkfjsdl",
        //   time: "2024-03-02 13:00:00",
        //   type: "Execution",
        //   msg: "this is a target to decision.",
        // },
        // {
        //   id: "0003",
        //   name: "What target to decision asdfljasldfnsoakdnglkjsdlansdkfjsdl",
        //   time: "2024-03-02 13:00:00",
        //   type: "Project",
        //   msg: "this is a target to decision.",
        // },
      ],

      createHeader: '',
      createDialog: false,
      createLoading: false,

      projectName: '',
      projectDescription: '',
      projectPurpose: '',

      isLogin: false,
      userID: '',
      token: '',

      getProjectLoading: false,
      projectItems: [],

      projectDeleteID: '',
      projectDeleteName: '',
      deleteDialog: false,
      sureDeleteloading: false,

      getProjectDemoLoading: false,
    }
  },
  computed: {
    TryDemoItemsComputed() {
      return this.TryDemoItems.map(target => {
        if (target.name.length > 40) {
          return {
            ...target,
            newName: target.name.slice(0, 40) + '...'
          };
        } else {
          return {
            ...target,
            newName: target.name
          };
        }
      });
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
        this.handleGetAllProjectDemo()
        if (this.isLogin) {
          this.handleGetAllProject();
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    this.handleGetAllProjectDemo()

    if (this.isLogin) {
      this.handleGetAllProject();
    }
  },

  methods: {
    handleQuickStart(name) {
      if (this.isLogin) {
        this.createDialog = true;
        this.createHeader = name;
        document.activeElement.blur();
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Please Login');
      }
    },

    handleCancelCreate() {
      this.createDialog = false;
      this.projectName = '';
      this.projectDescription = '';
      this.projectPurpose = '';
    },

    async handleCreateProject() {
      this.$store.commit('HandleloadingMsg', "project created loading");
      this.createLoading = true;

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectName': this.projectName,
        'projectDescription': this.projectDescription,
        'projectPurpose': this.projectPurpose,
        'hasDecision': (this.createHeader.endsWith('project') || this.createHeader.endsWith('decision')) ? 1 : 0,
        'hasExecution': (this.createHeader.endsWith('project') || this.createHeader.endsWith('execution')) ? 1 : 0,
        // 'decisionStatus': JSON.stringify({ msg: true, criteria: false, alternatives: false, tradeOff: false, analyse: false }),
        'createTime': Date.now(),
        'decisionLastChangeTime': Date.now(),
        'executionLastChangeTime': Date.now(),
      };

      let request_url = '/requestCreateProject';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.createLoading = false;
        this.$store.commit('HandleloadingMsg', "");
        this.$store.commit('HandleADecisionSnackbar', 'Success to create');
        this.createDialog = false;

        this.projectName = '';
        this.projectDescription = '';
        this.projectPurpose = '';

        this.handleGetAllProject();
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to create project:' + res.statusInfo);
      }
    },

    async handleGetAllProject() {
      this.getProjectLoading = true;

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {};

      let request_url = '/requestGetAllProject';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        res.data.forEach(item => {
          item.createTime = this.formatDate(parseInt(item.createTime))
          item.decisionLastChangeTime = this.formatDate(parseInt(item.decisionLastChangeTime))
          item.executionLastChangeTime = this.formatDate(parseInt(item.executionLastChangeTime))
          item.showButtons = false
        });
        this.projectItems = res.data
        this.$store.commit('HandleprojectItems', res.data);

        this.getProjectLoading = false;
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to get project:' + res.statusInfo);
      }
    },

    handleDeleteProject(projectID, projectName) {
      this.deleteDialog = true;
      this.projectDeleteID = projectID;
      this.projectDeleteName = projectName;
    },

    async handleSureDelete() {
      this.sureDeleteloading = true;
      
      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': this.projectDeleteID,
      };

      let request_url = '/requestDeleteProject';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.sureDeleteloading = false;
        this.deleteDialog = false;
        this.handleGetAllProject();

        this.$store.commit('HandleADecisionSnackbar', 'Success to delete the project')
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to delete the project: ' + res.statusInfo)
        this.sureDeleteloading = false;
      }
    },

    handleEnterProjectDecision(projectItem) {
      setLocalStorage("ADecisionProjectID", projectItem.projectID)
      this.$store.commit('HandleprojectItemMain', projectItem);
      let routerName = "Project/ProjectMsg"
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },

    handleEnterProjectExecution(projectItem) {
      this.$store.commit('HandleADecisionSnackbar', 'Will be online later');
      // setLocalStorage("ADecisionProjectID", projectItem.projectID)
      // this.$store.commit('HandleprojectItemMain', projectItem);
      // let routerName = "Project/ProjectMsg"
      // if (this.$router.currentRoute.path !== '/' + routerName) {
      //   this.$router.push('/' + routerName);
      // }
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    async handleGetAllProjectDemo() {
      this.getProjectDemoLoading = true;

      let request_header = {};
      let request_json = {};

      let request_url = '/requestGetAllProjectDemo';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        
        res.data.forEach(item => {
          let TryDemoItem = {};
          TryDemoItem['id'] = item.projectID
          TryDemoItem['name'] = item.projectName
          TryDemoItem['type'] = this.getProjectType(item.hasDecision, item.hasExecution)
          TryDemoItem['time'] = this.formatDate(parseInt(item.createTime))
          TryDemoItem['msg'] = item.projectDescription
          this.TryDemoItems.push(TryDemoItem)
        });

        this.getProjectDemoLoading = false;
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to get project demo:' + res.statusInfo);
      }
    },

    handleTryDemo(id) {
      if (this.isLogin) {
        this.handleCreateDemoProject(id)
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Please Login');
      }
    },

    async handleCreateDemoProject(id) {
      this.$store.commit('HandleloadingMsg', "project created loading");

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': id,
        'createTime': Date.now(),
        'decisionLastChangeTime': Date.now(),
        'executionLastChangeTime': Date.now(),
      };

      let request_url = '/requestCreateDemoProject';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.$store.commit('HandleloadingMsg', "");
        this.$store.commit('HandleADecisionSnackbar', 'Success to create');

        this.handleGetAllProject();
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to create project:' + res.statusInfo);
      }
    },

    getProjectType(hasDecision, hasExecution) {
      if ((hasDecision == 1) && (hasExecution == "")) {
        return 'Decision'
      } else if ((hasDecision == 1) && (hasExecution == 1)) {
        return 'Project'
      } else if ((hasDecision == "") && (hasExecution == 1)) {
        return 'Execution'
      }
    },
  }
};
</script>
