<template>
  <div>
    AboutView
  </div>
</template>

<script>

export default {
  name: 'AboutView',

  components: {
  },

  data() {
    return {
    }
  },

  methods: {
  }
};
</script>
