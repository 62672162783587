<template>
  <div>
    <LeftBarHome />
  
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
  
<script>
import LeftBarHome from '@/components/LeftBarComponents/LeftBarHome.vue';
  
export default {
  name: 'HomeMainView',
  
  components: {
    LeftBarHome,
  },
  
  data() {
    return {

    }
  },
  
  created() {
  },
  
  mounted() {

  },
  
  methods: {
  }
};
</script>
  