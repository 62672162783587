<template>
  <div>
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'BeginView',

  components: {
  },

  data() {
    return {
    }
  },

  methods: {
  }
};
</script>
