<template>
  <v-container>
    <div>
      <div class="d-flex align-center">
        <span class="mr-4 my-4 text-h6 font-weight-black">PROJECT MESSAGE</span>
        <v-divider></v-divider>
      </div>
      <div class="ml-4 mb-4 d-flex">
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text" small :disabled="isModifying" @click="handleMsgModify">
          modify
          <v-icon size="20" class="ml-1" color="white">mdi-rename-outline</v-icon>
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleMsgFromFile">
          Load File
          <v-icon size="20" class="ml-1" color="white">mdi-file-upload-outline</v-icon>
        </v-btn>
        <v-btn color="#439798" class="text-capitalize text-body-1 white--text ml-4" small :disabled="!isModifying" @click="handleSaveModify" :loading="modifyLoading">
          save
          <v-icon size="20" class="ml-1" color="white">mdi-content-save-edit-outline</v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
    </div>

    <div class="mt-8">
      <span class="text-body-2 ml-4">Project Name</span>
      <span class="text-body-2 red--text mx-2">*</span>
      <v-textarea
        v-model="projectDisplayName"
        class="mx-2 mt-2"
        rows="1"
        dense
        solo
        label="Project Name"
        :disabled="!isModifying"
      ></v-textarea>
    </div>
    
    <div class="mt-2">
      <span class="text-body-2 ml-4">Project Decision Purpose</span>
      <span class="text-body-2 red--text mx-2">*</span>
      <v-textarea
        v-model="projectDisplayPurpose"
        class="mx-2 mt-2"
        counter
        rows="3"
        solo
        label="Project Decision Purpose"
        :disabled="!isModifying"
      ></v-textarea>
    </div>

    <div class="mt-2">
      <span class="text-body-2 ml-4">Project Description</span>
      <span class="text-body-2 red--text mx-2">*</span>
      <v-textarea
        v-model="projectDisplayDescription"
        class="mx-2 mt-2"
        counter
        rows="5"
        solo
        label="Project Description"
        :disabled="!isModifying"
      ></v-textarea>
    </div>
  </v-container>
</template>
  
<script>
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'ProjectMsg',

  components: {
  },

  data() {
    return {
      isLogin: false,
      userID: '',
      token: '',

      projectItemMain: {},
      projectName: '',
      projectPurpose: '',
      projectDescription: '',

      projectDisplayName: '',
      projectDisplayPurpose: '',
      projectDisplayDescription: '',

      isModifying: false,
      modifyLoading: false,
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
        if (this.isLogin) {
          this.handleGetAllProject();
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.projectName = this.$store.getters.getprojectItemMain.projectName;
        this.projectPurpose = this.$store.getters.getprojectItemMain.projectPurpose;
        this.projectDescription = this.$store.getters.getprojectItemMain.projectDescription;
        this.initProjectMsg();
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.projectName = this.$store.getters.getprojectItemMain.projectName;
    this.projectPurpose = this.$store.getters.getprojectItemMain.projectPurpose;
    this.projectDescription = this.$store.getters.getprojectItemMain.projectDescription;
    this.initProjectMsg();
  },

  mounted() {
  },

  activated() {
    this.initProjectMsg();
  },
  
  methods: {
    initProjectMsg() {
      this.projectDisplayName = this.projectName;
      this.projectDisplayPurpose = this.projectPurpose;
      this.projectDisplayDescription = this.projectDescription;
    },

    handleMsgModify() {
      if (this.isLogin) {
        if (this.projectItemMain.projcetID == '') {
          this.$store.commit('HandleADecisionSnackbar', 'Please Wait')
        } else {
          this.isModifying = true;
        }
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Please Login')
      }
    },

    handleMsgFromFile() {

    },

    async handleSaveModify() {
      this.$store.commit('HandleloadingMsg', "project modified loading");
      this.modifyLoading = true;

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': this.$store.getters.getprojectItemMain.projectID,
        'projectName': this.projectDisplayName,
        'projectDescription': this.projectDisplayDescription,
        'projectPurpose': this.projectDisplayPurpose,
        'decisionLastChangeTime': Date.now(),
        'executionLastChangeTime': Date.now(),
      };

      let request_url = '/requestModifyProjectMsg';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.modifyLoading = false;
        this.$store.commit('HandleloadingMsg', "");
        this.$store.commit('HandleADecisionSnackbar', 'Success to modify');
        this.isModifying = false;

        this.$store.commit('HandlerefreshSingleProject', true);
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to create project:' + res.statusInfo);
      }
    },

    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hours = ('0' + date.getHours()).slice(-2);
      const minutes = ('0' + date.getMinutes()).slice(-2);
      const seconds = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  }
};
</script>

<style scoped>

</style>