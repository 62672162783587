import Vue from 'vue';
import VueRouter from 'vue-router';

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

import store from "@/store/index"; 
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'


// import AppView from '@/App.vue';
import HomeMainView from '@/views/HomeMainView.vue';
import ProjectMainView from '@/views/ProjectMainView.vue';

import BeginView from '@/views/FuncView/BeginView.vue';
import HomeView from '@/views/FuncView/HomeView.vue';
import DecisionView from '@/views/FuncView/DecisionView.vue';
import ExecutionView from '@/views/FuncView/ExecutionView.vue';
import AboutView from '@/views/FuncView/AboutView.vue';
import SettingView from '@/views/FuncView/SettingView.vue';

import Login from '@/components/SocialComponents/YA_Login.vue';

import FilesView from '@/views/FuncView/DecisionComponents/FilesView.vue';
import CriteriaView from '@/views/FuncView/DecisionComponents/CriteriaView.vue';
import TradeView from '@/views/FuncView/DecisionComponents/TradeView.vue';
import AlternativesView from '@/views/FuncView/DecisionComponents/AlternativesView.vue';
import AnalyseView from '@/views/FuncView/DecisionComponents/AnalyseView.vue';

import TestingView from '@/views/FuncView/ExecutionComponents/TestingView.vue';

import ProjectMsg from '@/views/ProjectView/DecisionProject/ProjectMsg.vue';
import ProjectFiles from '@/views/ProjectView/DecisionProject/ProjectFiles.vue';
import ProjectCriteria from '@/views/ProjectView/DecisionProject/ProjectCriteria.vue';
import ProjectAlternatives from '@/views/ProjectView/DecisionProject/ProjectAlternatives.vue';
import ProjectTradeOff from '@/views/ProjectView/DecisionProject/ProjectTradeOff.vue';
import ProjectAnalyseSummary from '@/views/ProjectView/DecisionProject/ProjectAnalyseSummary.vue';
import ProjectAnalysePreference from '@/views/ProjectView/DecisionProject/ProjectAnalysePreference.vue';
import ProjectAnalyseAlternatives from '@/views/ProjectView/DecisionProject/ProjectAnalyseAlternatives.vue';

import UserTradeOff from '@/components/ProjectComponents/TradeOffComponents/TradeOffComponent.vue';
import UserReview from '@/components/ProjectComponents/TradeOffComponents/ReviewComponent.vue';





Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'BeginView',
    component: BeginView,
    redirect: '/Home',
    meta:{
      'title':'ADecision'
    },
    children: [
      {
        path: 'Home',
        name: 'Home',
        component: HomeMainView,
        redirect: '/Home/Project',
        children: [
          {
            path: 'Project',
            name: 'Home/Project',
            component: HomeView,
            meta:{
              'title':'Home - ADecision'
            },
          },
          {
            path: 'Decision',
            name: 'HomeDecision',
            component: DecisionView,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'Files',
            name: 'HomeDecisionFiles',
            component: FilesView,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'Criteria',
            name: 'HomeDecisionCriteria',
            component: CriteriaView,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'Alternatives',
            name: 'HomeDecisionAlternatives',
            component: AlternativesView,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'Trade-off',
            name: 'HomeDecisionTrade-off',
            component: TradeView,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'Analyse',
            name: 'HomeDecisionAnalyse',
            component: AnalyseView,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'Execution',
            name: 'HomeExecution',
            component: ExecutionView,
            meta:{
              'title':'Execution - ADecision'
            },
          },
          {
            path: 'Testing',
            name: 'HomeExecutionTesting',
            component: TestingView,
            meta:{
              'title':'Execution - ADecision'
            },
          },
          {
            path: 'About',
            name: 'About',
            component: AboutView,
            meta:{
              'title':'About - ADecision'
            },
          },
          {
            path: 'Setting',
            name: 'Setting',
            component: SettingView,
            meta:{
              'title':'Setting - ADecision'
            },
          },
        ]
      },
      {
        path: 'Project',
        name: 'Project',
        component: ProjectMainView,
        redirect: '/Projcet/ProjectMsg',
        children: [
          {
            path: 'ProjectMsg',
            name: 'ProjectMsg',
            component: ProjectMsg,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'ProjectFiles',
            name: 'ProjectFiles',
            component: ProjectFiles,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'ProjectCriteria',
            name: 'ProjectCriteria',
            component: ProjectCriteria,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'ProjectAlternatives',
            name: 'ProjectAlternatives',
            component: ProjectAlternatives,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'ProjectTradeOff',
            name: 'ProjectTradeOff',
            component: ProjectTradeOff,
            children: [
              {
                path: '', 
                redirect: 'ProjectReviewTradeOff'
              },
              {
                path: 'ProjectSelectTradeOff',
                name: 'ProjectSelectTradeOff',
                component: UserTradeOff,
                meta:{
                  'title':'Decision - ADecision'
                },
              },
              {
                path: 'ProjectReviewTradeOff',
                name: 'ProjectReviewTradeOff',
                component: UserReview,
                meta:{
                  'title':'Decision - ADecision'
                },
              },
            ]
          },
          {
            path: 'ProjectAnalyseSummary',
            name: 'ProjectAnalyseSummary',
            component: ProjectAnalyseSummary,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'ProjectAnalysePreference',
            name: 'ProjectAnalysePreference',
            component: ProjectAnalysePreference,
            meta:{
              'title':'Decision - ADecision'
            },
          },
          {
            path: 'ProjectAnalyseAlternatives',
            name: 'ProjectAnalyseAlternatives',
            component: ProjectAnalyseAlternatives,
            meta:{
              'title':'Decision - ADecision'
            },
          },
        ]
      },
      {
        path: 'Login',
        name: 'Login',
        component: Login,
        meta:{
          'title':'Login - ADecision'
        },
      },
    ]
  },
  
  {
    path:"*",
    redirect:'/Home'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }

  // store.watch(() => store.state.projectMsg.projectItemMain, (newValue) => {
  //   let projectName = store.getters.getprojectItemMain.projectName;
  //   let routerPath = ""
  //   if (to.name.startsWith("HomeDecision")) {
  //     if (to.name == "HomeDecision") {
  //       routerPath = "/Home/Decision"
  //     } else {
  //       routerPath = "/Home/Decision/" + to.name.substring(12)
  //     }
  //   } else if (to.name.startsWith("HomeExecution")) {
  //     if (to.name == "HomeExecution") {
  //       routerPath = "/Home/Execution"
  //     } else {
  //       routerPath = "/Home/Execution/" + to.name.substring(13)
  //     }
  //   } else if (to.name.startsWith("Project")) {
  //     routerPath = "/" + projectName + "/" + to.name.substring(7)
  //   } else {
  //     routerPath = "/" + to.name
  //   }
  //   store.commit('HandlerouterPath', routerPath);
  // });
  
  let projectName = store.getters.getprojectItemMain.projectName;
  let routerPath = ""
  if (to.name.startsWith("HomeDecision")) {
    if (to.name == "HomeDecision") {
      routerPath = "/Home/Decision"
    } else {
      routerPath = "/Home/Decision/" + to.name.substring(12)
    }
  } else if (to.name.startsWith("HomeExecution")) {
    if (to.name == "HomeExecution") {
      routerPath = "/Home/Execution"
    } else {
      routerPath = "/Home/Execution/" + to.name.substring(13)
    }
  } else if (to.name.startsWith("Project")) {
    routerPath = "/" + projectName + "/" + to.name.substring(7)
  } else {
    routerPath = "/" + to.name
  }
  store.commit('HandlerouterPath', routerPath);

  next()
});




export default router;
