import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry.js';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import { pdfTextSplite } from '@/utils/PowerFileUtils/SpliteTextContent.js';


async function extractTextFromPdf(document) {
  const pdf = await pdfjsLib.getDocument(document).promise;

  const maxPages = pdf.numPages;
  let textContent = [];

  if (maxPages > 15) {
    return {
      'error': 'error: the pages of pdf is longer than 15'
    }
  } else {
    for (let i = 1; i <= maxPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const pageTextContent = content.items.map(item => item.str).join('');
      textContent.push(pageTextContent);
    }
    // const page = await pdf.getPage(1);
    // const content = await page.getTextContent();
    // console.log('content', content)
    return textContent;
  }
  
}

async function pdfLoadText(pdfFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let text = '';

    reader.onload = async () => {
      const dataUrl = reader.result;
      text = await extractTextFromPdf(dataUrl);
      resolve(text);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(pdfFile);
  });
}

export async function fileLoadText(fileDoc) {
  if (! fileDoc) {
    const requestContent = { 
      'code': 401,
      'msg': 'Error: File not found'
    }
    return requestContent;
  }

  try {
    const extension = fileDoc.name.split('.').pop();
    if (extension == 'pdf') {
      const pdfText = await pdfLoadText(fileDoc);
      if (pdfText.error) {
        const requestContent = {
          code: 403,
          'msg': 'Error: '+pdfText.error
        };
        return requestContent;
      } else {
        const isTextEmpty = pdfText.every(function(str) {
          return str === "";
        })
        if (isTextEmpty) {
          const requestContent = {
            code: 404,
            msg: 'Error: The document content is empty, or the document content cannot be obtained'
          };
          return requestContent;
        } else {
          const pdfSpliteText = pdfTextSplite(pdfText);
          const requestContent = {
            code: 200,
            content: pdfSpliteText,
            msg: 'Success'
          };
          return requestContent;
        }
      }
    }
  } catch (error) {
    const requestContent = { 
      'code': 402,
      'msg': 'Error: '+error
    }
    return requestContent;
  }
}
