
// export function pdfTextSplite(pdftextContent) {
//   // console.log('pdftextContent', pdftextContent);

//   const stepSize = 200; 
//   const charCountToCollect = 500; 

//   const wholeContent = pdftextContent.join('');

//   let dataContent = {};
//   dataContent['docContentArray'] = [];
//   dataContent['docPageNumberArray'] = [];

//   const lengthArray = pdftextContent.map(str => str.length);

//   if (wholeContent.length < charCountToCollect) {
//     dataContent.docContentArray.push( 1 );
//     dataContent.docPageNumberArray.push( wholeContent );
//   } else {
//     pdftextContent.forEach((pageContent, pageIndex) => {

//       let charIndex = 0;
//       let frontContentLength = lengthArray.slice(0, pageIndex).reduce((acc, current) => acc + current, 0);
  
//       while (charIndex < pageContent.length) {
//         if (pageIndex == pdftextContent.length-1) {
//           if (charIndex > pageContent.length-charCountToCollect+stepSize) {
//             break
//           }
//         }
//         const segment = wholeContent.slice(frontContentLength + charIndex, frontContentLength + charIndex + charCountToCollect);
        
//         dataContent.docContentArray.push( segment );
//         dataContent.docPageNumberArray.push( pageIndex + 1 );
        
//         charIndex += stepSize;
//       }
//     });
//   }

//   return dataContent;

// }

export function pdfTextSplite(pdftextContent) {

  let dataContent = {};
  dataContent['docContentArray'] = [];
  dataContent['docPageNumberArray'] = [];
  for(let i=0;i<pdftextContent.length;i++){
    dataContent.docContentArray.push(pdftextContent[i])
    dataContent.docPageNumberArray.push(i+1)
  }
  return dataContent;
}