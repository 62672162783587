<template>
  <div>
    <div class="d-flex align-center" style="height: 40px;">
      <span class="ml-2 text-body-1 grey--text text--darken-2 font-weight-bold">Question</span>
      <span class="mx-1 text-body-1 grey--text text--darken-2 font-weight-bold" v-if="questionHasFinished">-</span>
      <span class="mx-1 text-body-1 grey--text text--darken-2 font-weight-bold" v-else>{{ currentTradeOffItem }}</span>
      <v-progress-linear
        class="mx-3"
        :value="tradeOffPercent"
        color="#296162"
        height="7"
        rounded
      ></v-progress-linear>
      <span class="ml-2 text-body-1 grey--text text--darken-2 font-weight-bold">Progress</span>
      <span class="ml-1 text-body-1 grey--text text--darken-2 font-weight-bold">{{ tradeOffPercent }}</span>
      <span class="text-body-1 grey--text text--darken-2 font-weight-bold">%</span>
    </div>
    <v-divider></v-divider>

    <div>
      <v-row class="justify-center mt-16 pt-16" v-if="questionHasFinished">
        <v-col  cols="10" sm="10" md="4" lg="4" xl="4" class="d-flex justify-center flex-wrap">
          <v-container>
            <div class="d-flex justify-center">
              <v-icon x-large color="#296162" > mdi-check-all </v-icon>
            </div>
            <div class="d-flex justify-center my-4">
              <span class="text-body-1 grey--text text--darken-2 font-weight-bold">You have finished all questions.</span>
            </div>
            <div class="d-flex justify-center">
              <v-btn color="#439798" class="text-capitalize text-body-1 white--text" small @click="handleTradeOffReview">
                review & undo
                <v-icon size="20" class="ml-1" color="white">mdi-content-save-edit-outline</v-icon>
              </v-btn>
            </div>
          </v-container>
        </v-col>
      </v-row>

      <v-window v-model="tradeOffStep" v-else>
        <v-window-item v-for="(item, index) in tradeOffNotSelected" :key="index" :value="index">
          <div class="py-14" style="background-color: #f1f2f4;">
            <div class="d-flex justify-center mb-8">
              <span class="text-center text-h6 grey--text text--darken-2">Which of these two alternatives do you prefer?</span>
            </div>
            <v-row justify="center">
              <v-col cols="5">
                <v-card class="pt-4">
                  <div v-for="(value, key, tagIndex) in item.Tag1" :key="key" class="my-4 px-6">
                    <v-divider v-if="tagIndex !== 0"></v-divider>
                    <div class="mt-4">
                      <span class="text-body-1 grey--text text--darken-2 font-weight-bold">{{ key }}</span>
                    </div>
                    <div class="mt-3 ml-6">
                      <span class="text-body-1 font-weight-bold">{{ value }}</span>
                    </div>
                  </div>
                  <v-btn color="#439798" class="text-capitalize text-h6 white--text mt-10" block @click="handlelLeftchoice(item.number, index)">
                    <v-icon size="25" class="mr-1" color="white">mdi-gesture-tap</v-icon>
                    This One
                  </v-btn>
                </v-card>
              </v-col>
              <v-col cols="5">
                <v-card class="pt-4">
                  <div v-for="(value, key, tagIndex) in item.Tag2" :key="key" class="my-4 px-6">
                    <v-divider v-if="tagIndex !== 0"></v-divider>
                    <div class="mt-4">
                      <span class="text-body-1 grey--text text--darken-2 font-weight-bold">{{ key }}</span>
                    </div>
                    <div class="mt-3 ml-6">
                      <span class="text-body-1 font-weight-bold">{{ value }}</span>
                    </div>
                  </div>
                  <v-btn color="#439798" class="text-capitalize text-h6 white--text mt-10" block @click="handlelRightchoice(item.number, index)">
                    <v-icon size="25" class="mr-1" color="white">mdi-gesture-tap</v-icon>
                    This One
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
            <div class="d-flex justify-center">
              <v-btn color="#439798" class="text-capitalize text-h6 white--text mt-8 px-16" @click="handleEqualchoice(item.number, index)">
                <v-icon size="25" class="mr-1" color="white">mdi-gesture-tap</v-icon>
                They Are Equal
              </v-btn>
            </div>
          </div>
        </v-window-item>

      </v-window>
    </div>
    

    <v-footer app inset color="#ffffff" v-if="!questionHasFinished">
      <v-bottom-navigation
        color="#439798"
      >
        <v-btn @click="handleUndo">
          <span>Undo</span>
          <v-icon>mdi-arrow-left-top-bold</v-icon>
        </v-btn>
        <v-btn @click="handleRestart">
          <span>Restart</span>
          <v-icon>mdi-restart</v-icon>
        </v-btn>

        <v-btn @click="handleSkip">
          <span>Skip</span>
          <v-icon>mdi-arrow-right-top-bold</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-footer>

  </div>
</template>
  
<script>
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'TradeOffComponent',

  components: {
  },

  data() {
    return {
      tradeOffStep: 0,

      tradeOffItems: [],
      projectItemMain: {},
      tradeOffNotSelected: [],

      currentTradeOffItem: 0,
      tradeOffCurrentNum: 0,
      tradeOffNumber: 0,

      isLogin: false,
      userID: '',
      token: '',

      questionHasFinished: false,
      decisionStatus: {},
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.tradeOffItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionTradeOff || []);
        this.decisionCriteria = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
        this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});
        this.tradeOffNumber = this.tradeOffItems.length
        this.tradeOffCurrentNum = this.handleGetTradeOffCurrentNum(this.tradeOffItems)
        this.tradeOffNotSelected = this.handleGetTradeOffNotSelected(this.tradeOffItems)
        if (this.tradeOffNotSelected.length == 0) {
          this.questionHasFinished = true
        } else {
          this.questionHasFinished = false
          this.currentTradeOffItem = this.tradeOffNotSelected[0].number
        }
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.tradeOffItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionTradeOff || []);
    this.decisionCriteria = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
    this.decisionStatus = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionStatus || {});
    this.tradeOffNumber = this.tradeOffItems.length
    this.tradeOffCurrentNum = this.handleGetTradeOffCurrentNum(this.tradeOffItems)
    this.tradeOffNotSelected = this.handleGetTradeOffNotSelected(this.tradeOffItems)
    if (this.tradeOffNotSelected.length == 0) {
      this.questionHasFinished = true
    } else {
      this.questionHasFinished = false
      this.currentTradeOffItem = this.tradeOffNotSelected[0].number
    }
  },

  mounted() {

  },

  activated() {
    this.tradeOffStep = 0;
  },

  deactivated() {
    this.handleSaveModify()
  },

  computed: {
    tradeOffPercent() {
      return Math.floor((this.tradeOffCurrentNum / this.tradeOffNumber) * 100)
    },
  },
  
  methods: {
    handleNextQuestion(index) {
      if (this.tradeOffNotSelected.length == (index + 1)) {
        this.tradeOffCurrentNum += 1
        this.questionHasFinished = true
        this.handleTradeOffReview()
        // this.handleSaveModify()
      } else {
        this.tradeOffCurrentNum += 1
        this.tradeOffStep += 1
        this.currentTradeOffItem = this.tradeOffNotSelected[index + 1].number
      }
    },

    handlelLeftchoice(number, index) {
      this.tradeOffItems.forEach((item, tradeOffIndex)=> {
        if (item.number == number) {
          this.tradeOffItems[tradeOffIndex].choice = [true, false]
          this.tradeOffItems[tradeOffIndex].isFinished = true
        }
      })
      this.handleNextQuestion(index)
    },

    handlelRightchoice(number, index) {
      this.tradeOffItems.forEach((item, tradeOffIndex)=> {
        if (item.number == number) {
          this.tradeOffItems[tradeOffIndex].choice = [false, true]
          this.tradeOffItems[tradeOffIndex].isFinished = true
        }
      })
      this.handleNextQuestion(index)
    },

    handleEqualchoice(number, index) {
      this.tradeOffItems.forEach((item, tradeOffIndex)=> {
        if (item.number == number) {
          this.tradeOffItems[tradeOffIndex].choice = [true, true]
          this.tradeOffItems[tradeOffIndex].isFinished = true
        }
      })
      this.handleNextQuestion(index)
    },

    async handleSaveModify() {
      this.$store.commit('HandleloadingMsg', "trade-off modified loading");

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': this.$store.getters.getprojectItemMain.projectID,
        'decisionTradeOff': JSON.stringify(this.tradeOffItems),
        'decisionStatus': JSON.stringify(this.handleMakeDecisionStatus2()),
        'decisionLastChangeTime': Date.now(),
      };
      
      let request_url = '/requestModifyTradeOff';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        let analyseRes = await this.handleModifyAnalyse()

        if (analyseRes.status == 1) {
          this.$store.commit('HandleloadingMsg', "");
          this.$store.commit('HandleADecisionSnackbar', 'Success to modify');

          this.$store.commit('HandlerefreshSingleProject', true);
        }
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to create project:' + res.statusInfo);
      }
    },

    handleUndo() {
      if (this.tradeOffNotSelected[0].number !== this.currentTradeOffItem) {
        this.tradeOffStep -= 1
        if (this.tradeOffNotSelected[this.tradeOffStep].isFinished) {
          this.tradeOffCurrentNum -= 1
        }
        this.currentTradeOffItem = this.tradeOffNotSelected[this.tradeOffStep].number

      }
    },

    async handleRestart() {
      this.$store.commit('HandleloadingMsg', "trade-off restart loading");

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': this.$store.getters.getprojectItemMain.projectID,
        'decisionCriteria': this.$store.getters.getprojectItemMain.decisionCriteria,
        'decisionStatus': JSON.stringify(this.handleMakeDecisionStatus()),
        'decisionLastChangeTime': Date.now(),
      };
      
      let request_url = '/requestModifyCriteria';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.$store.commit('HandleloadingMsg', "");
        this.$store.commit('HandleADecisionSnackbar', 'Success to restart');

        this.$store.commit('HandlerefreshSingleProject', true);
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to restart:' + res.statusInfo);
      }
    },

    handleSkip() {
      if (this.tradeOffNotSelected[this.tradeOffNotSelected.length - 1].number !== this.currentTradeOffItem) {
        this.tradeOffStep += 1
        this.currentTradeOffItem = this.tradeOffNotSelected[this.tradeOffStep].number
      } else {
        this.questionHasFinished = true
        this.handleTradeOffReview()
      }
    },

    handleGetTradeOffCurrentNum(tradeOffItems) {
      let num = 0
      tradeOffItems.forEach((item, index)=> {
        if (item.isFinished) {
          num += 1
        }
      })
      return num
    },

    handleGetTradeOffNotSelected(tradeOffItems) {
      let items = []
      this.tradeOffItems.forEach((item, index)=> {
        if (!item.isFinished) {
          items.push(item)
        }
      })
      return items
    },

    handleTradeOffReview() {
      let routerName = "Project/ProjectTradeOff/ProjectReviewTradeOff"
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },

    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },

    async handleModifyAnalyse() {
      let tradeOffRate = this.handleMakeItemRate(this.tradeOffItems)
      let criteriaRate = this.handleMakeCriteriaRate(tradeOffRate)
      let alternativeRate = this.handleMakeAlternativeRate(criteriaRate, tradeOffRate)

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': this.$store.getters.getprojectItemMain.projectID,
        'decisionAnalyse': JSON.stringify({
          tradeOffRate: tradeOffRate,
          criteriaRate: criteriaRate,
          alternativeRate: alternativeRate,
        }),
        'decisionLastChangeTime': Date.now(),
      };
      
      let request_url = '/requestModifyAnalyse';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        return { status: 1 }
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to create project:' + res.statusInfo);
        return { status: 0 }
      }
    },

    handleMakeItemRate(array) {
      const result = [];
      const keyValueCount = {};
      array.forEach(obj => {
        const choice = obj.choice || [false, false]; 
        const isFinished = obj.isFinished || false;
        Object.keys(obj).forEach(tag => {
            const tagObj = obj[tag];
            if (typeof tagObj === 'object' && !Array.isArray(tagObj)) {
                Object.keys(tagObj).forEach(key => {
                    const value = tagObj[key];
                    const keyValue = `${key}:${value}`;
                    if (!keyValueCount[keyValue]) {
                        keyValueCount[keyValue] = { key, value, count: 0, chosenCount: 0, finishedCount: 0 };
                    }
                    keyValueCount[keyValue].count++;
                    if ((tag === 'Tag1' && choice[0]) || (tag === 'Tag2' && choice[1])) {
                        keyValueCount[keyValue].chosenCount++;
                    }
                    if (isFinished) {
                        keyValueCount[keyValue].finishedCount++;
                    }
                });
            }
        });
      });

      Object.values(keyValueCount).forEach(item => {
        result.push(item);
      });

      result.forEach((item, index)=> {
        if (item.finishedCount !== 0) {
          item.choiceRate = Number((item.chosenCount / item.finishedCount).toFixed(4))
        } else {
          item.choiceRate = Number(Number(0).toFixed(4))
        }
      })
      return result
    },

    handleMakeCriteriaRate(array) {
      let result = [];
      let criteriaItems = this.decisionCriteria

      criteriaItems.forEach(item => {
          const { criteriaTitle, criteriaLevelItems } = item;
          const halfLength = Math.ceil(criteriaLevelItems.length / 2);
          criteriaLevelItems.slice(0, halfLength).forEach(levelItem => {
              result.push({ key: criteriaTitle, value: levelItem.content });
          });
      });

      let upResult = [];
      result.forEach(objB => {
          let matchedA = array.filter(objA => objA.key === objB.key && objA.value === objB.value);

          let upChosenCount = 0;
          let allChosenCount = 0;

          if (matchedA.length > 0) {
              upChosenCount = matchedA.reduce((sum, obj) => sum + (obj.chosenCount || 0), 0);
              allChosenCount = array.filter(obj => obj.key === objB.key).reduce((sum, obj) => sum + (obj.chosenCount || 0), 0);
          }
          upResult.push({ key: objB.key, upChosenCount, allChosenCount });
      });

      let map = {};
      let singleResult = [];
      upResult.forEach(obj => {
          if (!(obj.key in map)) {
              map[obj.key] = obj.upChosenCount;
              singleResult.push({ key: obj.key, upChosenCount: obj.upChosenCount, allChosenCount: obj.allChosenCount });
          } else {
              map[obj.key] += obj.upChosenCount;
              let existingObjIndex = singleResult.findIndex(item => item.key === obj.key);
              if (existingObjIndex !== -1) {
                singleResult[existingObjIndex].upChosenCount += obj.upChosenCount;
              }
          }
      });

      singleResult.forEach((item, index)=> {
        if (item.allChosenCount !== 0) {
          item.criteriaRate = Number((item.upChosenCount / item.allChosenCount).toFixed(4))
        } else {
          item.criteriaRate = Number(Number(0).toFixed(4))
        }
        
      })

      return singleResult
    },

    handleMakeAlternativeRate(criteriaRate, tradeOffRate) {
      let totalRate = criteriaRate.reduce((sum, item) => sum + Number(item.criteriaRate), 0);
      let oneCriteriaRate = criteriaRate.map(item => ({
        key: item.key,
        oneCriteriaRate: Number((item.criteriaRate / totalRate).toFixed(4))
      }));

      let aMap = oneCriteriaRate.reduce((acc, item) => {
          acc[item.key] = item.oneCriteriaRate;
          return acc;
      }, {});

      let result = tradeOffRate.map(item => {
          const oneCriteriaRate = aMap[item.key] || 0;
          return {
              key: item.key,
              value: item.value,
              realChoiceRate: Number((item.choiceRate * oneCriteriaRate).toFixed(4))
          };
      });
      return result
    },

    handleMakeDecisionStatus() {
      let status = this.decisionStatus
      if (this.$store.getters.getprojectItemMain.decisionCriteria.length > 0) {
        status.criteria = true;
        status.tradeOff = false;
        status.analyse = false;
      } else {
        status.criteria = false;
        status.tradeOff = false;
        status.analyse = false;
      }
      return status
    },

    handleMakeDecisionStatus2() {
      let status = this.decisionStatus
      if (this.tradeOffCurrentNum == this.tradeOffNumber) {
        status.tradeOff = true;
        if (status.alternatives) {
          status.analyse = true;
        } else {
          status.analyse = false;
        }
      } else {
        status.tradeOff = false;
        status.analyse = false;
      }
      return status
    }
  }
};
</script>

<style scoped>

</style>