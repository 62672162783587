<template>
  <div>
    <v-navigation-drawer 
      width="240"
      app
      clipped 
      color="#fbfbfb"
      fixed
      v-model="leftBarTag"
      disable-resize-watcher
    >
      <div class="mx-3 mt-6">
        <v-btn color="#439798" class="text-capitalize text-h6 white--text" block @click="handleHome">
          Home & Projects
        </v-btn>
      </div>

      <v-container>
        <v-divider></v-divider>
      </v-container>

      <v-list dense>
        <v-list-item-group
          v-model="ColectItem"
          :mandatory='true'
        >
          <v-list-group
            v-for="(ItemTag) in ItemTags"
            :key="ItemTag.name"
            :value="true"
            color="#439798"
            @click="handleSelectTag(ItemTag.name)"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ ItemTag.name }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="([title, icon], i) in ItemTag.items"
              :key="i"
              link
              color="#439798"
              @click="handleColectItem()"
            >
              <v-list-item-title v-text="title" class="ml-4"></v-list-item-title>
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>

          <v-list-item
            color="#439798"
            v-for="(SingleItemTag) in SingleItemTags"
            :key="SingleItemTag.name"
            @click="handleSingleItem(SingleItemTag.name)"
          >
            <v-list-item-content class="black--text">
              <v-list-item-title>{{ SingleItemTag.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
  
<script>
export default {
  name: 'LeftBarHome',

  components: {
  },

  watch: {
    leftBarTag(newValue, oldValue) {
      if (!newValue) {
          this.$store.commit('HandleleftBarTag', false);
      }
    }
  },

  data() {
    return {
      ItemTags: [
        {
          name: "Decision Support",
          items: [
            ['Files', 'mdi-file-account-outline'],
            ['Criteria', 'mdi-tag-multiple-outline'],
            ['Alternatives', 'mdi-notebook-outline'],
            ['Trade-off', 'mdi-swap-horizontal'],
            ['Analyse', 'mdi-pickaxe'],
          ],
        },
        {
          name: "Execution Support",
          items: [
            ['Testing', 'mdi-plus-outline'],
          ],
        }
      ],
      SingleItemTags: [
        {
          name: "Setting",
        },
        {
          name: "About",
        },
      ],
      ColectItem: 0,

      leftBarTag: true,
    }
  },

  created() {
    this.$store.watch(
      (state) => state.systemMsg.leftBarTag, () => {
        this.leftBarTag = this.$store.getters.getleftBarTag;
      }, {
        deep: true
      }
    );
    this.leftBarTag = this.$store.getters.getleftBarTag;
    
  },

  methods: {
    handleHome() {
      let routerName = "Home/Project"
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },
    handleSelectTag(name) {
      let routerName = ""
      if (name.startsWith("Decision")) {
        routerName = "Home/Decision"
      } else if (name.startsWith("Execution")) {
        routerName = "Home/Execution"
      }
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },
    handleColectItem() {
      this.$nextTick(() => {
        let routerName = ""
        let reallyColectItem = this.ColectItem - 1
        if (reallyColectItem < this.ItemTags[0].items.length) {
          routerName = "Home/" + this.ItemTags[0].items[reallyColectItem][0]
        } else {
          routerName = "Home/" + this.ItemTags[1].items[reallyColectItem - this.ItemTags[0].items.length - 1][0]
        }

        if (this.$router.currentRoute.path !== '/' + routerName) {
          this.$router.push('/' + routerName);
        }
      });
    },
    handleSingleItem(name) {
      let routerName = "Home/" + name
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    }
  }
};
</script>

<style scoped>

</style>