<template>
  <div>
    <div class="mt-4">
      <v-card elevation="3">
        <v-card-title>
          Review List
        </v-card-title>
        <v-data-table
          hide-default-header
          :headers="headers"
          :items="tradeOffCurrentSelected"
          class="elevation-1"
        >
          <template v-slot:[`item.number`]="{ item }">
            <span class="font-weight-black">{{ item.number }}</span>
          </template>

          <template v-slot:[`item.Tag1`]="{ item }">
            <v-card class="my-1 py-1" flat :color="item.choice[0] ? '#67c9cb' : 'transparent'">
              <v-row v-for="(value, key) in item.Tag1" :key="key" no-gutters class="my-1">
                <v-col cols="6" class="d-flex justify-end">
                  <span class="mx-2 text-body-1 grey--text text--darken-2 font-weight-bold">{{ key }}</span>
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <span class="mx-2 text-body-1 font-weight-bold">{{ value }}</span>
                </v-col>
              </v-row>
            </v-card>
          </template>

          <template v-slot:[`item.Tag2`]="{ item }">
            <v-card class="my-1 py-1" flat :color="item.choice[1] ? '#67c9cb' : 'transparent'">
              <v-row v-for="(value, key) in item.Tag2" :key="key" no-gutters class="my-1">
                <v-col cols="6" class="d-flex justify-end">
                  <span class="mx-2 text-body-1 grey--text text--darken-2 font-weight-bold">{{ key }}</span>
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <span class="mx-2 text-body-1 font-weight-bold">{{ value }}</span>
                </v-col>
              </v-row>
            </v-card>
          </template>

          <template v-slot:[`item.actions`]="{ item, index }">
            <v-icon
              small
              class="mr-2"
              @click="handleEditItem(item, index)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="#439798" class="text-capitalize text-body-1 white--text my-4" small @click="handleTradeOffSelect">
              <div class="mx-4 d-flex align-center">
                <span>begin</span>
                <v-icon size="20" class="ml-1" color="white">mdi-arrow-right-bold-box-outline</v-icon>
              </div>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-dialog
      v-model="tradeOffDialog"
      width="888"
    >
      <v-card>
        <div class="py-14" style="background-color: #f1f2f4;">
          <div class="d-flex justify-center mb-8">
            <span class="text-center text-h6 grey--text text--darken-2">Which of these two alternatives do you prefer?</span>
          </div>
          <v-row justify="center">
            <v-col cols="5">
              <v-card class="pt-4">
                <div v-for="(value, key, tagIndex) in editTag1" :key="key" class="my-4 px-6">
                  <v-divider v-if="tagIndex !== 0"></v-divider>
                  <div class="mt-4">
                    <span class="text-body-1 grey--text text--darken-2 font-weight-bold">{{ key }}</span>
                  </div>
                  <div class="mt-3 ml-6">
                    <span class="text-body-1 font-weight-bold">{{ value }}</span>
                  </div>
                </div>
                <v-btn color="#439798" class="text-capitalize text-h6 white--text mt-10" block @click="handleConfirmChoice('Left')">
                  <v-icon size="25" class="mr-1" color="white">mdi-gesture-tap</v-icon>
                  This One
                </v-btn>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-card class="pt-4">
                <div v-for="(value, key, tagIndex) in editTag2" :key="key" class="my-4 px-6">
                  <v-divider v-if="tagIndex !== 0"></v-divider>
                  <div class="mt-4">
                    <span class="text-body-1 grey--text text--darken-2 font-weight-bold">{{ key }}</span>
                  </div>
                  <div class="mt-3 ml-6">
                    <span class="text-body-1 font-weight-bold">{{ value }}</span>
                  </div>
                </div>
                <v-btn color="#439798" class="text-capitalize text-h6 white--text mt-10" block @click="handleConfirmChoice('Right')">
                  <v-icon size="25" class="mr-1" color="white">mdi-gesture-tap</v-icon>
                  This One
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
          <div class="d-flex justify-center">
            <v-btn color="#439798" class="text-capitalize text-h6 white--text mt-8 px-16" @click="handleConfirmChoice('Equal')">
              <v-icon size="25" class="mr-1" color="white">mdi-gesture-tap</v-icon>
              They Are Equal
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'ReviewComponent',

  components: {
  },

  data() {
    return {
      tradeOffItems: [],
      projectItemMain: {},
      decisionCriteria: [],

      isLogin: false,
      userID: '',
      token: '',

      modifyLoading: false,
      tradeOffDialog: false,
      editTag1: undefined,
      editTag2: undefined,
      editIndex: undefined,

      headers: [
        {
          align: 'center',
          sortable: false,
          value: 'number',
          width: '66',
          divider: true
        },
        {
          align: 'center',
          sortable: false,
          value: 'Tag1',
          divider: true
        },
        {
          align: 'center',
          sortable: false,
          value: 'Tag2',
          divider: true
        },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      // desserts: [
      //   {
      //     Tag1: { NightLife: "OK", Beach: "Yes" },
      //     Tag2: { NightLife: "Excellent", Beach: "No" },
      //     choice: [true, false],
      //   },
      //   {
      //     Tag1: { NightLife: "OK", Beach: "Yes" },
      //     Tag2: { NightLife: "Excellent", Beach: "No" },
      //     choice: [false, true]
      //   },
      //   {
      //     Tag1: { NightLife: "OK", Beach: "Yes" },
      //     Tag2: { NightLife: "Excellent", Beach: "No" },
      //     choice: [true, true]
      //   },
      // ],
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        this.userID = this.$store.getters.getuserID;
        this.token = this.$store.getters.gettoken;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
    this.userID = this.$store.getters.getuserID;
    this.token = this.$store.getters.gettoken;
    
    this.$store.watch(
      (state) => state.projectMsg.projectItemMain, () => {
        this.projectItemMain = this.$store.getters.getprojectItemMain;
        this.tradeOffItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionTradeOff || []);
        this.decisionCriteria = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
      }, {
        deep: true
      }
    );
    this.projectItemMain = this.$store.getters.getprojectItemMain;
    this.tradeOffItems = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionTradeOff || []);
    this.decisionCriteria = this.handleConvertJSONToArray(this.$store.getters.getprojectItemMain.decisionCriteria || []);
  },

  mounted() {

  },

  computed: {
    tradeOffCurrentSelected() {
      let items = []
      this.tradeOffItems.forEach((item, index)=> {
        if (item.isFinished) {
          items.push(item)
        }
      })
      return items
    },
  },
  
  methods: {
    handleTradeOffSelect() {
      let routerName = "Project/ProjectTradeOff/ProjectSelectTradeOff"
      if (this.$router.currentRoute.path !== '/' + routerName) {
        this.$router.push('/' + routerName);
      }
    },

    handleConvertJSONToArray(jsonData) {
      try {
        if (jsonData == undefined) {
          return []
        } else {
          return JSON.parse(jsonData);
        }
      } catch (error) {
        return [];
      }
    },

    handleEditItem(item, index) {
      this.editTag1 = this.tradeOffItems[index].Tag1;
      this.editTag2 = this.tradeOffItems[index].Tag2;
      this.editIndex = index
      this.tradeOffDialog = true;
    },

    handleConfirmChoice(mode) {
      if (mode == 'Left') {
        this.tradeOffItems[this.editIndex].choice = [true, false]
      } else if (mode == 'Right') {
        this.tradeOffItems[this.editIndex].choice = [false, true]
      } else if (mode == 'Equal') {
        this.tradeOffItems[this.editIndex].choice = [true, true]
      }
      this.tradeOffDialog = false
      this.handleSaveModify()
    },

    async handleSaveModify() {
      this.$store.commit('HandleloadingMsg', "trade-off modified loading");

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': this.$store.getters.getprojectItemMain.projectID,
        'decisionTradeOff': JSON.stringify(this.tradeOffItems),
        'decisionLastChangeTime': Date.now(),
      };
      
      let request_url = '/requestModifyTradeOff';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        let analyseRes = await this.handleModifyAnalyse()

        if (analyseRes.status == 1) {
          this.$store.commit('HandleloadingMsg', "");
          this.$store.commit('HandleADecisionSnackbar', 'Success to modify');

          this.$store.commit('HandlerefreshSingleProject', true);
        }
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to create project:' + res.statusInfo);
      }
    },

    async handleModifyAnalyse() {
      let tradeOffRate = this.handleMakeItemRate(this.tradeOffItems)
      let criteriaRate = this.handleMakeCriteriaRate(tradeOffRate)
      let alternativeRate = this.handleMakeAlternativeRate(criteriaRate, tradeOffRate)

      let request_header = {
        email: this.userID,
        token: this.token
      };
      let request_json = {
        'projectID': this.$store.getters.getprojectItemMain.projectID,
        'decisionAnalyse': JSON.stringify({
          tradeOffRate: tradeOffRate,
          criteriaRate: criteriaRate,
          alternativeRate: alternativeRate,
        }),
        'decisionLastChangeTime': Date.now(),
      };
      
      let request_url = '/requestModifyAnalyse';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        return { status: 1 }
      } else {
        this.$store.commit('HandleADecisionSnackbar', 'Failed to create project:' + res.statusInfo);
        return { status: 0 }
      }
    },

    handleMakeItemRate(array) {
      const result = [];
      const keyValueCount = {};
      array.forEach(obj => {
        const choice = obj.choice || [false, false]; 
        const isFinished = obj.isFinished || false;
        Object.keys(obj).forEach(tag => {
            const tagObj = obj[tag];
            if (typeof tagObj === 'object' && !Array.isArray(tagObj)) {
                Object.keys(tagObj).forEach(key => {
                    const value = tagObj[key];
                    const keyValue = `${key}:${value}`;
                    if (!keyValueCount[keyValue]) {
                        keyValueCount[keyValue] = { key, value, count: 0, chosenCount: 0, finishedCount: 0 };
                    }
                    keyValueCount[keyValue].count++;
                    if ((tag === 'Tag1' && choice[0]) || (tag === 'Tag2' && choice[1])) {
                        keyValueCount[keyValue].chosenCount++;
                    }
                    if (isFinished) {
                        keyValueCount[keyValue].finishedCount++;
                    }
                });
            }
        });
      });

      Object.values(keyValueCount).forEach(item => {
        result.push(item);
      });

      result.forEach((item, index)=> {
        if (item.finishedCount !== 0) {
          item.choiceRate = Number((item.chosenCount / item.finishedCount).toFixed(4))
        } else {
          item.choiceRate = Number(Number(0).toFixed(4))
        }
      })

      let rateResult = this.decisionCriteria.map(aItem => {
        let abc = aItem.criteriaLevelItems.map(criteriaLevelItem=> {
          let choiceRate = 0;
          let chosenCount = 0;
          let count = 0;
          let finishedCount = 0;
          result.forEach((item, index)=> {
            if ((item.key == aItem.criteriaTitle) && (item.value == criteriaLevelItem.content)) {
              choiceRate = item.choiceRate;
              chosenCount = item.chosenCount;
              count = item.count;
              finishedCount = item.finishedCount;
            }
          });
          return {
            key: aItem.criteriaTitle,
            value: criteriaLevelItem.content,
            choiceRate: choiceRate,
            chosenCount: chosenCount,
            count: count,
            finishedCount: finishedCount,
          };
        })
        return abc
      });
      return rateResult.flat()
    },

    handleMakeCriteriaRate(array) {
      let result = [];
      let criteriaItems = this.decisionCriteria

      criteriaItems.forEach(item => {
          const { criteriaTitle, criteriaLevelItems } = item;
          const halfLength = Math.ceil(criteriaLevelItems.length / 2);
          criteriaLevelItems.slice(0, halfLength).forEach(levelItem => {
              result.push({ key: criteriaTitle, value: levelItem.content });
          });
      });

      let upResult = [];
      result.forEach(objB => {
          let matchedA = array.filter(objA => objA.key === objB.key && objA.value === objB.value);

          let upChosenCount = 0;
          let allChosenCount = 0;

          if (matchedA.length > 0) {
              upChosenCount = matchedA.reduce((sum, obj) => sum + (obj.chosenCount || 0), 0);
              allChosenCount = array.filter(obj => obj.key === objB.key).reduce((sum, obj) => sum + (obj.chosenCount || 0), 0);
          }
          upResult.push({ key: objB.key, upChosenCount, allChosenCount });
      });

      let map = {};
      let singleResult = [];
      upResult.forEach(obj => {
          if (!(obj.key in map)) {
              map[obj.key] = obj.upChosenCount;
              singleResult.push({ key: obj.key, upChosenCount: obj.upChosenCount, allChosenCount: obj.allChosenCount });
          } else {
              map[obj.key] += obj.upChosenCount;
              let existingObjIndex = singleResult.findIndex(item => item.key === obj.key);
              if (existingObjIndex !== -1) {
                singleResult[existingObjIndex].upChosenCount += obj.upChosenCount;
              }
          }
      });

      singleResult.forEach((item, index)=> {
        if (item.allChosenCount !== 0) {
          item.criteriaRate = Number((item.upChosenCount / item.allChosenCount).toFixed(4))
        } else {
          item.criteriaRate = Number(Number(0).toFixed(4))
        }
        
      })

      return singleResult
    },

    handleMakeAlternativeRate(criteriaRate, tradeOffRate) {
      let totalRate = criteriaRate.reduce((sum, item) => sum + Number(item.criteriaRate), 0);
      let oneCriteriaRate = criteriaRate.map(item => ({
        key: item.key,
        oneCriteriaRate: Number((item.criteriaRate / totalRate).toFixed(4))
      }));

      let aMap = oneCriteriaRate.reduce((acc, item) => {
          acc[item.key] = item.oneCriteriaRate;
          return acc;
      }, {});

      let result = tradeOffRate.map(item => {
          const oneCriteriaRate = aMap[item.key] || 0;
          return {
              key: item.key,
              value: item.value,
              realChoiceRate: Number((item.choiceRate * oneCriteriaRate).toFixed(4))
          };
      });
      return result
    },
  }
};
</script>

<style scoped>

</style>